import pic from "../assets/img/pic-login.png";
import logo from "../assets/img/logo.svg";
import eye from "../assets/img/eye.svg";

const handleLogin = () => {

}

export const Login = () => {
  return (
    <>
      <div className="flex justify-center text-Headline">
        <div className="hidden lg:block w-full min-h-screen bg-[#B3EADD]">
          <div className="flex justify-center items-center w-full h-full max-w-3xl ms-auto">
            <div className="max-w-lg mx-6">
              <img src={pic} alt="" className="w-full" />
            </div>
          </div>
        </div>
        <div className="w-full min-h-screen">
          <div className="flex justify-center items-center w-full h-full max-w-[44rem] me-auto ms-auto lg:ms-0">
            <div className="w-full max-w-[540px] mx-6 my-8">
              <a href="/" className="block w-full">
                <img src={logo} alt="" className="ml-auto max-w-[70%]" />
              </a>
              <div className="shadow-loginbox p-6 py-8 sm:p-11 mt-6 sm:mt-11">
                <div className="text-2xl sm:text-5xl leading-7 sm:leading-[56px] font-medium">高雄市教育雲</div>
                <div className="text-2xl sm:text-5xl leading-7 sm:leading-[56px] font-medium mb-7 sm:mb-14">
                  <span className="text-purple me-1">Open ID</span>認證服務
                </div>
                <div className="mb-7 sm:mb-9">
                  <label htmlFor="userid" className="">
                    請輸入帳號
                  </label>
                  <div className="mt-3">
                    <input
                      type="text"
                      name="userid"
                      id="userid"
                      autoComplete=""
                      placeholder="Account"
                      className="w-full h-14 rounded-md border border-[#4285F4] text-sm px-4 pe-10"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="pasw" className="">
                    請輸入密碼
                  </label>
                  <div className="relative mt-3">
                    <input
                      type="password"
                      name="pasw"
                      id="pasw"
                      placeholder="Password"
                      className="w-full h-14 rounded-md border border-[#ADADAD] text-sm px-4 pe-10"
                    />
                    <div className="flex items-center absolute top-0 bottom-0 right-0 pe-4 cursor-pointer">
                      <img src={eye} alt="" />
                    </div>
                  </div>
                </div>
                <div className="text-sm text-right">
                  <a href="" className="text-red hover:opacity-80">忘記密碼？</a>
                </div>
                <div className="text-[#8D8D8D] my-6 sm:mt-10 sm:mb-12">
                  <div>一、若您是本市教職員，可登入<a href="" className="text-green font-medium hover:opacity-80"><u>資訊服務入口網</u></a>，利用『個人服務/OpenID』申請帳號或修改密碼</div>
                  <div className="mt-2">二、若您是本市高中職(含)以下的學生，初次密碼請詢問學校，之後可由<a href="" className="text-green font-medium hover:opacity-80"><u>此處</u></a>修改密碼</div>
                </div>
                <a href="/main" className="block h-14 w-full bg-primary text-center leading-[54px] text-white shadow-login hover:opacity-80 cursor-pointer" onClick={()=>handleLogin()}>登入</a>
              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  )

}