import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css';
interface TextEditorProps {
  defaultValue: any;
  onChange: (value: string) => void;
}

export const TextEditor: React.FC<TextEditorProps> = ({ defaultValue, onChange }) => {
  const myColors = [
    "black",
    "white",
    "red",
    "green",
    "blue",
    "cyan",
    "magenta",
    "yellow",
    "purple",
  ];
  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: myColors }],
      [{ background: myColors }],
      ["bold", "italic", "underline", "strike"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ]
  };

  const formats = [
    // "header",
    // "blockquote",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align"
  ];

  const [code, setCode] = useState(defaultValue);

  const handleTextChange = (content: string) => {
    setCode(content);
    onChange(content);
  };

  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (quillRef.current) {
      const quillInstance = quillRef.current.getEditor();
     // console.log(quillInstance);
    }
  }, []);
  
  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      modules={modules}
      formats={formats}
      value={code}
      onChange={handleTextChange}      
    />
  )
}