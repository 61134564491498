// 計畫專區頁面
export const Plan = () => {
  return (
    <div>
      <div className="mb-16">
        <div className="flex items-center staticBanner bg-[#ACDFF0] relative">
          <div className="staticBannerContent">
            <img
              src="/images/planBanner.svg"
              alt="banner"
              className="staticBannerImg"
            />
          </div>
        </div>
        <div className="staticSection">
          <div className="sectionContent">
            <h3 className="sectionTitle text-Headline">計畫簡介</h3>
            <ul className="space-y-[2em] text-text text-sm sm:text-base lg:text-2xl">
              <li className="">
                <h4 className="font-bold mb-2">壹、計畫依據</h4>
                <p className="ps-[2em]">
                  行政院110年12月16日核定教育部「推動中小學數位學習精進方案」（院臺教字第1100036597號函）。
                </p>
              </li>
              <li className="">
                <h4 className="font-bold mb-2">貳、計畫目標</h4>
                <ul className="list-[cjk-ideographic] ps-[4em] space-y-[0.5em]">
                  <li>
                    充實學校教學軟體及數位內容，符合教師教學需求，並提供教師備課便利性與提升教學多樣性。
                  </li>
                  <li>
                    支援偏遠地區學校師生1人1臺學習用行動載具（以下簡稱學習載具），非偏遠地區學校依據班級總數每6班配發1班學習載具，引導學生運用資訊科技提升學習成效，培養學生自主學習、合作學習、問題解決和創造等能力，並培養其建立健康、合理與合法的資訊科技使用態度和習慣。
                  </li>
                  <li>
                    培訓教師應用數位科技進行教學，並精進教師數位教學能力，結合學習載具、教學軟體及數位內容，更有效率的支援教師教學與學生學習，促進教學多樣化。
                  </li>
                  <li>
                    藉由大數據分析，為學生提供量身定制個人化的學習途徑外，亦提供教師、學校與家長不同角色學習分析報告，達成因材施教及適性化教學。
                  </li>
                  <li>
                    呼應UNESCO公布的「2030年教育仁川宣言」實現包容、公平的優質教育目標，朝向「偏鄉學校數位優先」的原則，並於疫情期間支援經濟弱勢、多子家庭學生有學習載具可以使用，縮減教育落差達公平教育的目標。
                  </li>
                </ul>
              </li>
              <li className="">
                <h4 className="font-bold mb-2">參、實施對象</h4>
                <p className="ps-[2em]">
                  各直轄市政府教育局及縣市政府所轄中小學（含縣市立、私立完全中學國中部及國小部）。
                </p>
              </li>
              <li className="">
                <h4 className="font-bold mb-2">肆、計畫實施重點</h4>
                <ul className="list-[cjk-ideographic] ps-[4em] space-y-[0.5em]">
                  <li>
                    本計畫之實施重點在於促進全國中小學師生科技輔助教學與學習與數位素養等能力的提升。
                  </li>
                  <li>
                    工作項目包括持續數位學習推動辦公室運作、學習載具與行動充電車管理、校園網路環境維運、數位內容及教學軟體購置、教師培訓、實施科技輔助教學與學習扶助、相關數據蒐集與分析等；由縣市政府統一調查所轄中小學數位學習軟、硬體需求並規劃細部推動事項。
                  </li>
                  <li>
                    數位學習推動辦公室應綜整全縣（市）數位學習推動工作，包括BYOD、THSD、雙語數位學伴、5G智慧學習學校、5G新科技學習示範學校等計畫，促進學校師生科技輔助教學與學習以及數位素養等能力之提升。
                  </li>
                  <li>
                    推動輔導與增能，組成輔導團隊，並辦理到校輔導，了解學校使用數位工具輔助教學與學習情形、執行困難與問題，提供必要協助與輔導。辦理教師增能培訓對象包括縣市政府所轄中小學（含縣市立、私立完全中學國中部及國小部）之教師與資訊組長（或資訊負責人員），並開放國立國民中小學教師參加。
                  </li>
                  <li>
                    協助學校資訊與網路，包含聯外及校園有線、無線網路架構規劃，定期評估網路及頻寬使用需求，滾動修正，支援師生網路使用問題偵測及排除機制。
                  </li>
                  <li>
                    教學軟體及數位內容購置與管理本計畫補助學校購置教育部「校園數位內容與教學軟體選購名單」內產品，需經學校正式會議決議後選購，由推動辦公室彙整送教育部備查。
                  </li>
                  <li>
                    辦理成效評估了解本計畫補助之軟、硬體是否有被充分應用，規劃數位學習成效評估機制，協助學校了解學生學習成效是否改善。
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="staticSection">
          <div className="sectionContent">
            <h3 className="sectionTitle text-Headline">教師研習指標</h3>
            <ul className="space-y-[2em] text-text text-sm sm:text-base lg:text-2xl">
              <li className="">
                <h4 className="font-bold mb-2">壹、推動教師數位學習增能</h4>
                <p className="ps-[2em]">
                  建構在職教師數位教學增能培訓機制，透過補助地方政府、高中職及國立國中小學辦理數位學習工作坊及數位素養等課程，奠定教師基礎數位教學知能，另規劃進階課程及講師培訓，鼓勵教師持續增能。
                </p>
                <ol className="ps-[2em] list-disc mt-[0.5em]">
                  <li>
                    基礎課程：包含「科技輔助自主學習概論」與「數位學習平臺應用」，培養教師了解學習載具、數位學習平臺數據診斷分析報告與自主學習教學法整合之作法基本知能。
                  </li>
                  <li>
                    進階課程：提供教師選修增能，包含「科技輔助自主學習工作坊」、以專題導向為主之「PBL教學應用工作坊」、多元數位教學模式之「數位教學指引培力工作坊」、「各領域/科目數位教學工作坊」。
                  </li>
                </ol>
                <img
                  src="/images/architecture5.jpg"
                  alt="規劃培訓教師應用數位科技進行教學，並精進教師數位教學能力"
                  className="py-[1em]"
                />
              </li>
              <li className="">
                <h4 className="font-bold mb-2">貳、培訓對象 :</h4>
                <p className="ps-[2em]">
                  縣市政府所轄中小學（含縣市立、私立完全中學國中部及國小部）之正式及代理教師（不包含代課教師及其他特聘人員）。
                </p>
              </li>
              <li className="">
                <h4 className="font-bold mb-2">
                  參、教師增能關鍵績效指標（KPI）:
                </h4>
                <ul className="list-[cjk-ideographic] ps-[4em] space-y-[0.5em]">
                  <li>
                    每校須於113年前完成培訓對象教師A1.數位學習工作坊（一）及A2.數位學習工作坊（二）課程（共6小時研習），112年累計培訓編制內教師數至少達編制教師總數82%。
                  </li>
                  <li>
                    教師參與數位素養相關議題增能研習至少3小時，每年培訓人數占該校教師編制數10%（每年提報培訓數量），以增進網路科技正向運用之效益。
                  </li>
                  <li>
                    學校資訊組長（或資訊負責人員）完成相關增能研習內容包含校園網路連線、學習載具管理及數位學習平臺帳號管理與登入問題等。
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
