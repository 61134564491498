import { RouterProvider } from "react-router-dom";
import { useRouter } from "./router";
import './assets/css/semantic.css';
import "@fontsource/noto-sans-tc";
import "@fontsource/noto-sans-tc/400.css";
import "@fontsource/noto-sans-tc/chinese-traditional.css";
import './App.css';
import './assets/css/home.css';
import './assets/css/staticPages.css';
export const App = () => {
  const router = useRouter();
  return (
    <RouterProvider router={router} />
  );
}

