import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const handleLink =(innerLink:string)=>{
    window.location.assign(innerLink);
}

export const handleLinkWithBlank =(innerLink:string)=>{
  window.open(innerLink,'_blank');
}

export const buildTimeRange = (start: string, end: string) => {
  if ((end==="") && (start!=="")) {
    return '自'+dayjs(start).format('YYYY-MM-DD') + '起';
  }
  if ((start==="") && (end!=="")) {
    return '到'+dayjs(end).format('YYYY-MM-DD') + '止';
  }  
  if ((start==="") && (end==="")) {
    return '無限期';
  }
  return '自'+dayjs(start).format('YYYY-MM-DD') + '至' + dayjs(end).format('YYYY-MM-DD') ;
}

export const maskChineseName =(chName:string):string =>{
  if(chName === null || chName === undefined) {
      return '';
  }
  const trimmedInput = chName.trim();

  if (trimmedInput.length === 0) {
      return '';
  }

  // Get the first and last characters
  const firstChar = trimmedInput[0];
  const lastChar = trimmedInput[trimmedInput.length - 1];

    return firstChar+'O'+lastChar ;
}

export   const showMessage = (msgTitle:string,msgText:string) => {
  withReactContent(Swal).fire({
    title: msgTitle,
    text: msgText,
    showDenyButton: false,
    showConfirmButton: true,
    confirmButtonText: "確定",
    showCancelButton: false,
    reverseButtons: false,
    customClass: {
      confirmButton: 'bg-warning',
    }
  })
}
