import pic1 from "../assets/img/pic1.png";
import pic2 from "../assets/img/pic2.png";
import pic3 from "../assets/img/pic3.png";
import triangle from "../assets/img/triangle.svg";
import { CarouselPage } from "./CarouselPage";
import { News } from './News';
import {useUserContext} from "../components/auth/userContext";

export const Home = () => {
  const {userInfo} = useUserContext();
  console.log(userInfo);

  return (
    <>
      <div>
        <CarouselPage />
      </div>
      <News />
      <div className='bg-[#408680] mt-9 lg:mt-[72px]'>
        <div className='content flex flex-col-reverse lg:flex-row justify-between items-center lg:items-start gap-x-16 gap-y-6 px-6 mx-auto p-16'>
          <div className='max-w-3xl w-full text-center lg:text-left font-medium'>
            <div className='text-lg lg:text-6xl text-[#F9F871]'>共創師生學習之旅</div>
            <div className='text-lg lg:text-6xl text-white pt-1 pb-6 lg:pt-6 lg:pb-10'>探索數位學習的無限可能</div>
            <div className='text-sm lg:text-2xl text-white font-normal text-justify'>歡迎蒞臨高雄市數位學習入口網，這裡不僅是知識的樂園，更是師生互動與合作的據點。我們提供多樣化的學習工具和互動功能，讓教學和學習變得更加生動有趣。高雄市數位學習入口網具備單一登入功能，師生可使用Open ID輕鬆登入，享受豐富的學習資源。我們致力打造先進、便利且有趣的數位學習環境，歡迎高雄市的師生加入我們，共同探索無限的學習可能。</div>
          </div>
          <div className='w-full max-w-[418px] px-8 lg:p-0'>
            <img src={pic1} alt="" />
          </div>
        </div>
      </div>
      <img src={triangle} alt="" className='mx-auto -mt-1 w-20 lg:w-36' />

      <div className='content flex flex-col lg:flex-row justify-between items-center lg:items-start gap-x-16 gap-y-6 px-6 mx-auto mt-6 lg:mt-16'>
        <div className='w-full max-w-[418px] px-8 lg:p-0'>
          <img src={pic2} alt="" />
        </div>
        <div className='max-w-3xl w-full text-center lg:text-left font-medium'>
          <div className='text-lg lg:text-6xl text-[#DA5D5D]'>優化教學體驗</div>
          <div className='text-lg lg:text-6xl text-Headline pt-1 pb-6 lg:pt-6 lg:pb-10'>專屬於教師的多樣化資源</div>
          <div className='text-sm lg:text-2xl font-normal text-justify'>透過高雄市數位學習入口網，教師們可以輕鬆地使用網路資源進行教學，豐富課堂內容，增加學生的學習樂趣。同時，我們提供了線上報修、WebITR差勤系統、線上預約等功能，讓您能夠輕鬆管理課堂運作、處理學生學習問題，並安排與學生的會議時間。這一系列功能的整合，讓您能夠在高雄市數位學習入口網中高效完成所有業務，更專注於教學本身。</div>
          <a href="./api/auth/login_ischool?linkSignIn=khoidc&restore_url=/main"><div className='bt-purple text-sm lg:text-3xl font-normal w-[96px] lg:w-[200px] whitespace-nowrap py-2 lg:py-[18px] mt-5 ml-auto cursor-pointer hover:opacity-80'>馬上體驗</div></a>
        </div>
      </div>

      <div className='content flex flex-col-reverse lg:flex-row justify-between items-center lg:items-start gap-x-16 gap-y-6 px-6 mx-auto mt-6 lg:mt-16'>
        <div className='max-w-3xl w-full text-center lg:text-left font-medium'>
          <div className='text-lg lg:text-6xl text-[#DA5D5D]'>數位資源一站式</div>
          <div className='text-lg lg:text-6xl text-Headline pt-1 pb-6 lg:pt-6 lg:pb-10'>學習暢遊無阻不再繁瑣複雜</div>
          <div className='text-sm lg:text-2xl font-normal text-justify'>透過高雄市數位學習入口網，學生們將能夠輕鬆使用天下雜誌群知識庫、均一教育平台、學習吧、PaGamO、LoiLoNote School、Adobe Creative Cloud、威力導演等多樣化的數位資源。師生只需透過高雄市Open ID帳號登入，即可享受無縫的學習體驗，拓展知識、培養技能，為未來的成長鋪就堅實的基礎。</div>
          <a href="./api/auth/login_ischool?linkSignIn=khoidc&restore_url=/main"><div className='bt-green text-sm lg:text-3xl font-normal w-[96px] lg:w-[200px] whitespace-nowrap py-2 lg:py-[18px] mt-5 ml-auto cursor-pointer hover:opacity-80'>馬上體驗</div></a>
        </div>
        <div className='w-full max-w-[418px] px-8 lg:p-0'>
          <img src={pic3} alt="" />
        </div>
      </div>


      <div className='p-7 lg:p-16'></div>
    </>
  )
}