import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import config from "../SysConfig.json";
import { useEffect, useState } from 'react';


export const CarouselPage = () => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    // Fetch the JSON file
    let imgUrs: string[] = [];
    config.Banners.forEach((banner) => {
      imgUrs.push(banner.image_url);
    });
    setImages(imgUrs);

  }, []);

  return (
    <>
        <Carousel showThumbs={false} showStatus={false} autoPlay={true} showArrows={false} infiniteLoop={true}>
          {images.map((imageUrl, index) => (
            <div key={index}>
              <img src={imageUrl} alt={`Banner ${index + 1}`} />
            </div>
          ))}
        </Carousel>
    </>
  )

}