export class myFavoriteHelper
{
    static async getMyFavoriteByRole(role: string) {
        
        const resp = await fetch(`api/application/myfavorites/${role}`);
        return await resp.json() || [];
    }

    static async getMyFavRatingStatByRole(role: string) {
        
        const resp = await fetch(`api/application/myfavorites_rating_stat/${role}`);
        return await resp.json() || [];
    }

    static async addMyFavorite(sid: string) {
        const resp = await fetch(`api/application/myfavorite/${sid}`, {
            method: 'POST'
        });
        return await resp.json();
    }

    static async removeMyFavorite(sid: string) {
        const resp = await fetch(`api/application/myfavorite/${sid}`, {
            method: 'DELETE'
        });
        return await resp.json();
    }
}