export interface Staff {
  name: string;
  role: string;
  title: string;
  phone: string;
  email: string;
  workItem: string[];
  cardType: string;
}

export const StaffData: Staff[] = [
  {
    name: "梁華蓁",
    role: "雙語暨數位學習推動辦公室",
    title: "執行長",
    phone: "(07)7260089 #101",
    email: "esaliang@gm.kh.edu.tw",
    workItem: ["綜理雙語暨數位學習推動辦公室各項任務工作"],
    cardType: "r",
  },
  {
    name: "翁主任",
    role: "數位學習推動辦公室",
    title: "主任",
    phone: "(07)7260089 #111",
    email: "im.xpower@gm.kh.edu.tw",
    workItem: [
      "襄理數位學習推動辦公室各項任務工作",
      "承執行長之命，辦理雙語學習推動辦公室各項工作之規劃及執行",
    ],
    cardType: "g",
  },
  {
    name: "陳主任",
    role: "雙語學習推動辦公室",
    title: "主任",
    phone: "(07)7260089 #191",
    email: "cyl@gm.kh.edu.tw",
    workItem: [
      "襄理雙語學習推動辦公室各項任務工作",
      "承執行長之命，辦理雙語學習推動辦公室各項工作之規劃及執行",
    ],
    cardType: "p",
  },
  {
    name: "朱小姐",
    role: "雙語學習推動辦公室",
    title: "專任行政組長",
    phone: "(07)7260089 #192",
    email: "jch0907@gm.kh.edu.tw",
    workItem: [
      "襄理雙語學習推動辦公室各項任務工作",
      "雙語學習推動辦公室各項工作與交辦事項之規劃與執行",
    ],
    cardType: "p",
  },
  {
    name: "侯小姐",
    role: "數位學習推動辦公室",
    title: "專案教學研究專員",
    phone: "(07)7260089 #113",
    email: "cwhou@gm.kh.edu.tw",
    workItem: [
      "數位學習專業社群計畫",
      "特殊教育數位學習實驗計畫",
      "數位學習支持關懷實施計畫",
      "全市授權數位內容與教學軟體（LoiLoNote、作文易學堂、中文能力雲端診斷系統）相關業務",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "g",
  },
  {
    name: "林先生",
    role: "數位學習推動辦公室",
    title: "專案教學研究專員",
    phone: "(07)7260089 #114",
    email: "khelearning@gm.kh.edu.tw",
    workItem: [
      "數位學習工作坊（A1、A2、A3素養、B系列研習及部分廠商研習）實施計畫",
      "學校研習媒合協助",
      "學習載具、載具充電車及學習載具管理系統（MDM）相關業務",
      "數位學習支持關懷實施計畫",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "g",
  },
  {
    name: "柯小姐",
    role: "數位學習推動辦公室",
    title: "專案教學研究專員",
    phone: "(07)7260089 #115",
    email: "amyko@gm.kh.edu.tw",
    workItem: [
      "手把手陪伴計畫",
      "校長科技領導學校實施計畫(含自主學習節)",
      "數位學習支持關懷實施計畫",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "g",
  },
  {
    name: "張小姐",
    role: "數位學習推動辦公室",
    title: "專案教學研究專員",
    phone: "(07)7260089 #116",
    email: "kerry.c@gm.kh.edu.tw",
    workItem: [
      "恆星學校學校計畫 (含自主學習節)",
      "數位學習支持關懷實施計畫",
      "全市授權數位內容與教學軟體（Lumio）相關業務",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "g",
  },
  {
    name: "黃小姐",
    role: "數位學習推動辦公室",
    title: "專案教學研究專員",
    phone: "(07)7260089 #117",
    email: "yuchien291@gm.kh.edu.tw",
    workItem: [
      "數位學習支持關懷實施計畫",
      "全市授權數位內容與教學軟體（PaGamO、翰林雲端學院）相關業務",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "g",
  },
  {
    name: "張小姐",
    role: "數位學習推動辦公室",
    title: "專案行政專員",
    phone: "(07)7260089 #118",
    email: "acps91026@gm.kh.edu.tw",
    workItem: [
      "國民中小學實施計畫（業務費及四級距鐘點費、公開觀議課、成效評估等）",
      "5G智慧學習學校推動計畫",
      "BYOD&THSD計畫",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "g",
  },
  {
    name: "朱小姐",
    role: "數位學習推動辦公室",
    title: "專案行政專員",
    phone: "(07)7260089 #119",
    email: "judy.chu@gm.kh.edu.tw",
    workItem: [
      "校園數位內容與教學軟體採購相關業務",
      "校園數位內容與教學軟體應用情形調查",
      "全市授權數位內容與教學軟體（Adobe、威力導演、HyRead 國語日報&電子雜誌&學習童書）相關業務",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "g",
  },
  {
    name: "郭老師",
    role: "數位學習推動辦公室",
    title: "專案行政組長",
    phone: "(07)7260089 #121",
    email: "jenshow@gm.kh.edu.tw",
    workItem: [
      "辦理數位學習政策相關公文文書業務及經費核銷業務",
      "教育部數位學習政策與教學之行政服務窗口",
      "教育部數位學習精進方案補助經費請撥及核結事項",
      "教學軟體於教學現場應用之諮詢(天下雜誌)",
      "市府、議會及教育局端施政相關資料彙整與聯繫",
      "其他交辦事項之執行",
    ],
    cardType: "g",
  },
  {
    name: "林小姐",
    role: "數位學習推動辦公室",
    title: "專案行政專員",
    phone: "(07)7260089 #122",
    email: "wan129@gm.kh.edu.tw",
    workItem: [
      "高中職實施計畫（業務費及鐘點費、公開觀議課、成效評估等）",
      "教學與輔導管考填報相關業務",
      "全市授權數位內容與教學軟體（myViewBoard、ClassSwift、Wordwall）相關業務",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "g",
  },
  {
    name: "陳小姐",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #161",
    email: "iris_ipad@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具（iPad）維修及操作問題協助",
      "生生用平板載具管理系統（MDM－Jamf Pro）管理及操作問題協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "陳先生",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #162",
    email: "bright_ipad@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具（iPad）維修及操作問題協助",
      "生生用平板載具管理系統（MDM－Jamf Pro）管理及操作問題協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "薛小姐",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #164",
    email: "gina_ipad@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具（iPad）維修及操作問題協助",
      "生生用平板載具管理系統（MDM－Jamf Pro）管理及操作問題協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "張小姐",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #165",
    email: "amanda_ipad@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具（iPad）維修及操作問題協助",
      "生生用平板載具管理系統（MDM－Jamf Pro）管理及操作問題協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "王小姐",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #166",
    email: "esther_ipad@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具（iPad）維修及操作問題協助",
      "生生用平板載具管理系統（MDM－Jamf Pro）管理及操作問題協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "林先生",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #167",
    email: "pawn_ipad@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具（iPad）維修及操作問題協助",
      "生生用平板載具管理系統（MDM－Jamf Pro）管理及操作問題協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "鄧先生",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #163",
    email: "then1080@gm.kh.edu.tw",
    workItem: [
      "負責雙語及數位推動教學、iPad載具相關影片拍攝與剪輯",
      "協助與教師聯繫溝通數位教案的設計及執行",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "黃先生",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #168",
    email: "thomas@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具（ASUS Chromebook、ASUS觸控筆電、Samsung平板、HP平板）維修及操作問題協助",
      "生生用平板載具管理系統（MDM－intune、Workspace）管理及操作問題協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "葛先生",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #169",
    email: "henry@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具（ASUS Chromebook、ASUS觸控筆電、Samsung平板、HP平板）維修及操作問題協助",
      "生生用平板載具管理系統（MDM－intune、Workspace）管理及操作問題協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "邱先生",
    role: "數位學習推動辦公室",
    title: "網路工程師",
    phone: "(07)7260089 #170",
    email: "hubert.chiu@gm.kh.edu.tw",
    workItem: [
      "生生用平板載具相關網路問題諮詢協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "顏先生",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #171",
    email: "gimmy.yen@netease.com.tw",
    workItem: [
      "生生用平板載具相關網路問題諮詢協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
  {
    name: "周先生",
    role: "數位學習推動辦公室",
    title: "駐點工程師",
    phone: "(07)7260089 #172",
    email: "john.chou@netease.com.tw",
    workItem: [
      "生生用平板載具相關網路問題諮詢協助",
      "教育部數位學習推動精進計畫相關業務及其他臨時交辦事項",
    ],
    cardType: "y",
  },
];
