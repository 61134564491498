export const ErrorPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold text-red-500 mb-4">Oops!</h1>
      <p className="text-gray-600 text-lg mb-4">
        We're sorry, but an error has occurred.
      </p>
      <p className="text-gray-600 text-lg mb-4">
        Please try again later or contact support.
      </p>
      <button className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded" onClick={() => { window.history.back() }}>
        Go Back
      </button>
    </div>
  )
}