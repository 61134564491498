
import React, { ReactNode } from 'react';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
  img?: string;
  children: ReactNode;
}

export const Modal = ({ show, onClose, title = "", content = "", img = "", children }: ModalProps) => {
  if (!show) {
    return null;
  }
  return (
    <div className="flex fixed inset-0 bg-Headline bg-opacity-60 z-50 overflow-y-auto p-4">
      <div className="relative self-center bg-white rounded-lg shadow-xl w-full max-w-5xl lg:mx-auto overflow-y-auto max-h-full">
        <div>{children}</div>
      </div>
    </div>
  );
}
