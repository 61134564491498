import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.svg';
import menuIcon from '../assets/img/menu.svg';
import closeIcon from '../assets/img/close.svg';
import downIcon from '../assets/img/down.svg';
import { Dialog, DialogPanel, Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/react'
import { useUserContext } from '../components/auth/userContext';

const products = [
  { name: '研習資訊', href: 'info' },
  { name: '線上報修', href: 'repair' },
  { name: '軟體採購', href: 'procurement' },
  { name: '計畫專區', href: 'plan' },
];

export const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { userInfo } = useUserContext();
  console.log('userInfo', userInfo);
  //處理登入
  const handleLogin = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const url = "/api/auth/login_ischool?linkSignIn=khedu&restore_url=/main";
    window.location.href = url;
  };

  //處理登出
  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const url = "/api/auth/logout?restore_url=/";
    window.location.href = url;
  }

  return (
    <>
      <div className="content flex justify-between items-center text-xl gap-4 text-Headline mx-auto p-4">
        <a href="/">
          <img src={logo} alt="" className="w-52 sm:w-auto" />
        </a>
        <div>
          <header className="bg-white">
            <nav className="mx-auto flex max-w-7xl items-center justify-between pe-4" aria-label="Global">
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <img src={menuIcon} alt="" />
                </button>
              </div>
              <PopoverGroup className="hidden lg:flex items-center lg:gap-x-8 xl:gap-x-12">
                <NavLink to="/unit">
                  <div className="text-xl leading-6 text-gray-900 p-2 nav hover:opacity-80">
                    單位介紹
                  </div>
                </NavLink>

                <Popover className="relative">
                  <PopoverButton className="flex items-center gap-x-1 text-xl leading-6 text-gray-900 outline-none border-b-2 border-white hover:border-gray-200 p-2 hover:opacity-80">
                    服務項目
                    <img src={downIcon} alt="" className="h-5 w-5 flex-none" aria-hidden="true" />
                  </PopoverButton>

                  <PopoverPanel
                    transition
                    className="absolute -left-4 top-full z-10 mt-3 w-32 bg-white text-center"
                  >
                    <div className="py-5">
                      {products.map((item) => (
                        <div
                          key={item.name}
                          className="px-3 py-2 text-xl leading-6"
                        >
                          <div className="">
                            {/*<a href={item.href} className={`${location.pathname === '/' + item.href && 'active'}`}>*/}
                            <a href={item.href}>
                              <div className='nav p-2'>{item.name}</div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </PopoverPanel>
                </Popover>
                {
                  (userInfo) ?
                  <> 
                  <NavLink to="/main" end>
                  <div className="text-xl leading-6 text-gray-900 p-2 nav hover:opacity-80">
                    教學資源
                  </div>
                  </NavLink>
                  <div className="text-xl leading-6 text-main p-2 nav">
                    {userInfo.fullName}
                  </div>
                  </> :
                  <>
                    <NavLink to="/resource" end>
                    <div className="text-xl leading-6 text-gray-900 p-2 nav hover:opacity-80">
                      教學資源
                    </div>
                    </NavLink>
                  </>

                }
                {
                  userInfo && userInfo.isAdmin &&
                  <NavLink to="/main/announce" end>
                    <div className="text-xl leading-6 text-gray-900 p-2 nav hover:opacity-80">
                      後台管理
                    </div>
                  </NavLink>
                }
                {
                  (userInfo) ? 
                    <a href="/api/auth/logout?restore_url=/">
                      <div className="text-xl leading-6 text-gray-900 p-2 nav hover:opacity-80">
                        登出
                      </div>
                    </a>
                  :
                    <a href="/api/auth/login_ischool?linkSignIn=khoidc&restore_url=/main">
                      <div className="text-xl leading-6 text-gray-900 p-2 nav hover:opacity-80">
                        登入
                      </div>
                    </a>                  
                }



              </PopoverGroup>
            </nav>
            <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              <DialogPanel className="fixed right-0 top-0 z-10 overflow-y-auto bg-white pt-6 me-5 w-auto">
                <div className="flex items-center justify-between">
                  <div></div>
                  <button
                    type="button"
                    className="pe-4 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <img src={closeIcon} alt="" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="divide-y divide-gray-500/10">
                    <div className="pb-6">
                      <a href="unit" className="block text-base leading-7 text-gray-900 hover:opacity-80 px-10 py-2">
                        單位介紹
                      </a>
                      {[...products].map((item) => (
                        <a key={item.name} href={item.href} className="block text-base leading-7 text-gray-900 hover:opacity-80 px-10 py-2">
                          {item.name}
                        </a>
                      ))}
                      {
                        (userInfo) ?
                        <> 
                          <a href="/main" className="block text-base leading-7 text-gray-900 hover:opacity-80 px-10 py-2">
                            教學資源
                          </a>
                        </> :
                        <>
                          <a href="/resource" className="block text-base leading-7 text-gray-900 hover:opacity-80 px-10 py-2">
                            教學資源
                          </a>
                        </>
                      }
                      {
                        (userInfo) &&                      
                          <div className="block text-center text-base leading-7 text-main hover:opacity-80 px-10 py-2">
                            {userInfo.fullName}
                          </div>
                      }

                      {
                        userInfo && userInfo.isAdmin &&
                        <a href='/main/announce' className='block text-center text-base leading-7 text-gray-900 hover:opacity-80 px-10 py-2'>
                          後台管理
                        </a>

                      }                     

                      <hr />
                      {
                        (userInfo) ? 
                          <a href="/api/auth/logout?restore_url=/">
                            <div className="block text-center text-base leading-7 text-gray-900 hover:opacity-80 px-10 py-2">
                              登出
                            </div>
                          </a>
                        :
                          <a href="/api/auth/login_ischool?linkSignIn=khoidc&restore_url=/main">
                            <div className="block text-center text-base leading-7 text-gray-900 hover:opacity-80 px-10 py-2">
                              登入
                            </div>
                          </a>                  
                      }
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </Dialog>
          </header>

          {/* <header className="bg-white">
            <div className="text-base md:text-xl whitespace-nowrap pe-4">
              <PopoverGroup className="flex items-center gap-4 md:gap-x-12">
                <div className="text-[#5BB6AF] py-2 hover:!opacity-80">
                  李知恩
                </div>

                <Popover className="relative">
                  <PopoverButton className="flex items-center gap-x-1 leading-6 outline-none border-b-2 border-white py-2 hover:opacity-80">
                    後台管理
                    <img src={downIcon} alt="" className="h-5 w-5 flex-none" aria-hidden="true" />
                  </PopoverButton>

                  <PopoverPanel
                    transition
                    className="absolute -left-4 top-full z-10 mt-3 bg-white"
                  >
                    <div className="md:py-5">
                      <div key='1' className="px-3 md:py-2 leading-6" >
                        <a href='/main/announce'>
                          <div className='p-2'>公告管理</div>
                        </a>
                      </div>
                      <div key='2' className="px-3 md:py-2 leading-6" >
                        <a href='/'>
                          <div className='p-2'>登出</div>
                        </a>
                      </div>

                    </div>
                  </PopoverPanel>
                </Popover>
              </PopoverGroup>
            </div>
          </header> */}
        </div>
      </div>

    </>
  )

}