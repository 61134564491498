export class ratingHelper
{
    static async getServiceRating(sid:string) {
        
        const resp = await fetch(`api/application/get_rating/${sid}`);
        return await resp.json() || {};
    }

    static async getServiceRatingList(sid:string) {
         const resp = await fetch(`api/application/get_rating_list/${sid}`);
        return await resp.json() || [];
    }

    static async addServiceRating(sid:string,rating:number,title:string,content:string) {
        const req={
            service_id:sid,
            rating:rating,
            title:title,
            content:content,
        }
        console.log('Add rating:',req)
        const resp = await fetch(`/api/application/add_rating`,{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(req)
        })
        return await resp.json() || {};
    }

    static async updateServiceRating(rid:string,rating:number,title:string,content:string) {
        const req={
            rating:rating,        
            title:title,
            content:content,
        }
        const resp = await fetch(`/api/application/update_rating/${rid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(req)
        });
        return await resp.json() || {};    
    }
    static async setActServiceRating(rids:number[],activate:boolean) {
        const req={        
            rating_ids:JSON.stringify(rids),
            activate:activate,
        }
        const resp = await fetch(`/api/application/activate_rating`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(req)
        });
        return await resp.json();    
    }


}