//軟體採購頁面
import { News } from "./News";
export const Procurement = () => {
  // 控制是否為填答時間
  const isAnswerTime = false;
  const Btn = () => {
    if (isAnswerTime) {
      return (
        <div className="px-6 py-8 sm:px-12 sm:py-16 grid grid-cols-2 gap-4 md:gap-10 text-center text-[0.9em]">
          <button className="overflow-hidden bg-secondary text-white rounded md:rounded-2xl flex items-center shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] hover:shadow-[0px_8px_8px_0px_rgba(0,0,0,0.25)] hover:-translate-y-1 transition duration-300">
            <div className="p-2 sm:p-4 bg-white w-1/4 max-w-[160px] h-full">
              <img
                src="/images/check-form.svg"
                alt=""
                className="w-auto h-full object-contain object-center"
              />
            </div>
            <div className="grow">進行填答</div>
          </button>
          <a
            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRT1FNLwDKbXNL0hNTmz1jQugOW6QcB6NqZ2DP1Ib8aO1B0_HL3mVuwt7cXFJHH51xaDi8Lz0-yuX7e/pubhtml"
            target="_blank"
            rel="noopener noreferrer"
            className=""
          >
            <button className="w-full h-full overflow-hidden bg-primary text-white rounded md:rounded-2xl flex items-center shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] hover:shadow-[0px_8px_8px_0px_rgba(0,0,0,0.25)] hover:-translate-y-1 transition duration-300">
              <div className="p-2 sm:p-4 bg-white w-1/4 h-full">
                <img
                  src="/images/zoom-view.svg"
                  alt=""
                  className="w-auto h-full object-contain object-center"
                />
              </div>
              <div className="grow ">查閱填答進度</div>
            </button>
          </a>
        </div>
      );
    } else {
      return (
        <div className="px-6 py-8 sm:px-12 sm:py-16  text-center text-[0.9em]">
          #現在非填答時間
        </div>
      );
    }
  };
  return (
    <div>
      <div className="mb-20">
        <div className="flex items-center staticBanner bg-[#CFF2C6] relative">
          <div className="staticBannerContent">
            <img
              src="/images/procurementBanner.svg"
              alt="banner"
              className="staticBannerImg"
            />
            <div className="text-base sm:text-lg md:text-3xl absolute w-[40%] max-w-[400px] -translate-x-[47%] max-h-full overflow-auto">
              <h2 className=" font-black text-primaryDark leading-tight ">
                校園數位內容與 <br />
                教學軟體採購
              </h2>
              <p className="text-secondaryDark mt-[0.5em] text-[0.65em] leading-normal">
                獲得市場上多元的數位內容和教學軟體產品，以達教材更生動及教學更多元之目標。
              </p>
            </div>
          </div>
        </div>
        <div className="staticSection">
          <div className="sectionContent">
            <div className="border-2 border-headline relative text-sm sm:text-2xl 2xl:text-4xl">
              <div className="flex justify-center items-center font-bold absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-2 text-Headline text-nowrap">
                <span className="text-[0.55em]">〇〇〇</span>
                <h3 className="px-1">應用情形調查</h3>
                <span className="text-[0.55em]">〇〇〇</span>
              </div>
              <Btn />
            </div>
          </div>
        </div>
        <News />
      </div>
    </div>
  );
};
