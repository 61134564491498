import config from '../SysConfig.json';

export class attachHelper {
    static async uploadFile(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('sourceFileName', file.name);
  
        try {
          const response = await fetch(`/api/storage/private/${config.uploadDirectiory}`, {
            method: 'POST',
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
            return data;         
          } else {
            throw new Error('Failed to upload file');
          }
        } catch (error) {
          console.error('Error uploading file:', error);      
        }
    }
    static async deleteFile(fileName:string) {
        try {
            const response = await fetch(`/api/storage/private/${config.uploadDirectiory}/${fileName}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                throw new Error('Failed to delete file');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    }
}


