import config from '../SysConfig.json';
import { Attachment, MessageItem } from '../components/value_objects';
import dayjs from 'dayjs';
export class annoHelper {
    static async getOpenConditionalAnno(query: string, limit: number, page: number) {
        
        const resp = await fetch(`/api/open_messages/get_anns?q=${query}&limit=${limit}&page=${page}`);
        return await resp.json() || [];
    }
    static async getOpenAnnoCount() {
        const resp = await fetch(`/api/open_messages/get_anns_count`);
        return await resp.json() || [];
    }
    static async getConditionalAnno(query: string, limit: number, page: number) {
        const resp = await fetch(`/api/announcement/sent_anns?q=${query}&limit=${limit}&page=${page}`);
        return await resp.json() || [];
    }
    static async create(message:MessageItem){
      /*  const atts=message.attachments.map((att)=>({
            file_name:att.file_name,
            file_path:att.file_path,
            method:'create',
            source_file_name:att.source_file_name,
        }))*/
        
        const req={
            attachments:message.attachments,
            content:message.content,
            end_at:(message.end_at===null)? null:dayjs(message.end_at).format('YYYY-MM-DD'),
            is_top:message.is_top,
            start_at:(message.start_at===null)? null:dayjs(message.start_at).format('YYYY-MM-DD'),
            title:message.title,
            to_parent:false,
            to_student:true,
            to_teacher:true,
            unit_name:message.unit_name,
        }
        const resp = await fetch(`/api/announcement/create`,{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(req)
        })
        return await resp.json()
    }
    static async update(message:MessageItem){
        const req={
            attachments:message.attachments,
            content:message.content,
            end_at:(message.end_at===null)? null:dayjs(message.end_at).format('YYYY-MM-DD'),
            is_top:message.is_top,
            start_at:(message.start_at===null)? null:dayjs(message.start_at).format('YYYY-MM-DD'),
            title:message.title,
            to_parent:false,
            to_student:true,
            to_teacher:true,
            unit_name:message.unit_name,
        }
        const resp = await fetch(`/api/announcement/update/${message.id}`,{
            method:"PUT",
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(req)
        })
        return await resp.json()
    }
    static async delete(id:number){
          const resp = await fetch(`/api/announcement/delete/${id}`,{
            method:"DELETE",
            headers: {
                'Content-Type': 'application/json',
              },
          })
          return await resp.json()

    }
}
