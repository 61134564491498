import { useEffect, useState } from 'react';
import editIcon from '../../assets/img/edit.svg';
import removeIcon from '../../assets/img/remove.svg';
import addIcon from '../../assets/img/add.svg';
import searchIcon from '../../assets/img/search.svg';
import refreshIcon from '../../assets/img/refresh.svg';
import uploadIcon from '../../assets/img/upload.svg';
import fileIcon from '../../assets/img/file.svg';
import { Modal } from "../../components/Modal";
import { TextEditor } from "../../components/TextEditor";
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { zhTW } from "date-fns/locale/zh-TW";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { annoHelper } from '../../dal/annoHelper';
import { useNavigate } from 'react-router-dom';
import { buildTimeRange } from '../../modules/utils';
import { set } from 'react-datepicker/dist/date_utils';
import { Attachment, MessageItem } from '../../components/value_objects';
import { error } from 'console';
import config from '../../SysConfig.json';
import { attachHelper } from '../../dal/attachHelper';

registerLocale('zhTW', zhTW);

export const Announce = () => {
  const [allAnnounce, setAllAnnounce] = useState<any[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [reload, setReload] = useState(false);
  const [currItem, setCurrItem] = useState<MessageItem>(
    {
      id: 0,
      title: '',
      content: '',
      is_top: false,
      start_at: new Date(),
      end_at: new Date(),
      unit_name: '',
      attachments:[]
    }
  );
  const [editMode, setEditMode] = useState(0); // 0:新增, 1:編輯

  console.log('curItem',currItem)
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {    
    setModalOpen(false);
  };

  const navigate = useNavigate();

  const handleItemClick = (item:any) => {
    navigate('/main/content', { state: { item } });
  };

  const handleEditItem = (item:any) => {
    console.log('item',item)
    const atts:Attachment[] = [];
    if(item.attachments)
    {
      item.attachments.map((att:any) => {
        const a:Attachment = {
          id: att.id,
          file_name: att.file_name,
          file_path: att.file_path,
          source_file_name: att.source_file_name,
          method:'',
        }
        atts.push(a);
      })
    }       
    setCurrItem({
      id: item.id,
      title: item.title,
      content: item.content,
      is_top: item.is_top,
      start_at: (item.start_at)? new Date(dayjs(item.start_at).format('YYYY-MM-DD')): null,
      end_at: (item.end_at)?new Date(dayjs(item.end_at).format('YYYY-MM-DD')) : null,
      unit_name: item.unit_name,
      attachments:atts,
    });
    setModalOpen(true)
    setEditMode(1);
    openModal();
  };

  const handleAddItem = () => {
    const newItem: MessageItem = {
      id: 0,
      title: '',
      content: '',
      is_top: false,
      start_at: dayjs().toDate(),
      end_at: dayjs().toDate(),
      unit_name: '',
      attachments:[]
    };
    setCurrItem(newItem);
    setEditMode(0);
    openModal();
  };


  const loadData = async () => {
    const annos = await annoHelper.getConditionalAnno(query,-1,0);
    setAllAnnounce(annos);   
    setEditMode(0);
    //console.log('annos', annos)
  }
  useEffect(() => {
    loadData();
  }, [query]);

  useEffect(() => {
    if (reload) {
      loadData();
      setReload(false);
    }
  }, [reload])

  const showSure = () => {
    withReactContent(Swal).fire({
      title: '刪除訊息',
      text: '您確定要刪除此訊息嗎？',
      showDenyButton: false,
      showConfirmButton: true,
      confirmButtonText: "確定",
      showCancelButton: true,
      cancelButtonText: `取消`,
      reverseButtons: false,
      customClass: {
        confirmButton: 'bg-warning',
        cancelButton: 'bg-primary'
      }
    })
  }

  const showMessage = (errTitle:string,errText:string) => {
    withReactContent(Swal).fire({
      title: errTitle,
      text: errText,
      showDenyButton: false,
      showConfirmButton: true,
      confirmButtonText: "確定",
      showCancelButton: false,
      reverseButtons: false,
      customClass: {
        confirmButton: 'bg-warning',
      }
    })
  }

  const handleStartDate = (date: Date | null) => {
    if((currItem.end_at) &&(date)&& (currItem.end_at < date))
    {
      showMessage('日期區間錯誤','上架日期不可晚於下架日期');
      return;
    } 
    setCurrItem({...currItem,start_at:date})
  }
  const handleEndDate = (date: Date | null) => {
    if((currItem.start_at) &&(date)&& (currItem.start_at > date))
    {
      showMessage('日期區間錯誤', '下架日期不可早於上架日期');
      return;
    }
    setCurrItem({...currItem,end_at:date})
  }

  const handleUnitName = (unitName:string) => {
    if(unitName.length>7)
    {
      showMessage('公告單位', '公告單位不可超過7個字');
    }
    else
    {
      setCurrItem({...currItem,unit_name:unitName})
    }
  }

  const handleTitle = (tit:string) => {
    setCurrItem({...currItem,title:tit})
  }
  
  const handleUpload = async(event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.files && event.target.files.length > 0)
    {
        const data = await attachHelper.uploadFile(event.target.files[0]);
        const newAttt:Attachment = {
          id: 0,
          file_name: data.files[0].fileName,
          file_path: data.files[0].fileUrl,
          source_file_name: event.target.files[0].name,
          method:'create',
        }
        setCurrItem({...currItem,attachments:[...currItem.attachments,newAttt]})
        showMessage('檔案上傳訊息', '檔案上傳成功');
    }
  }

  const handleDelFile = async(fileName:string) => {
      const updatedAttachments = currItem.attachments.map(attachment => 
        attachment.file_name === fileName ? { ...attachment, method: 'delete' } : attachment
      );
      setCurrItem({...currItem,attachments:updatedAttachments})
      showMessage('檔案刪除訊息', '檔案刪除完成');   
   }

  const handleSave = async()=>{
    if(currItem.unit_name==='')
    {
      showMessage('公告輸入','公告單位不可空白')
      return;
    }
    if(currItem.title==='')
    {
      showMessage('公告輸入','公告主旨不可空白')
      return;
    }
 //   if((!currItem.start_at) && (!currItem.end_at))
 //   {
 //     showMessage('公告輸入','上架時間及下架時間不可同時空白')
 //     return;
 //   }
    if(editMode===0) //新增儲存
    {
      const data=await annoHelper.create(currItem)
    }
    else
    {
      const promises=[];
      promises.push(annoHelper.update(currItem))      
      if(currItem.attachments)
      {
        const attPromise=currItem.attachments
        .filter((att:Attachment) => att.method==='delete')
        .map((att:Attachment) => attachHelper.deleteFile(att.file_name))          
        promises.push(...attPromise)
      }
      await Promise.all(promises);

    }
   // setModalOpen(false)
   setReload(true)
   closeModal()    
  }

  const handleDelItem = async (item: MessageItem) => {
    try {
      // Create an array to hold all promises
      const promises = [];
  
      // Add the item delete promise
      promises.push(annoHelper.delete(item.id));
  
      // Add the attachment delete promises if there are any
      if (item.attachments) {
        const attachmentPromises = item.attachments.map((att: any) => attachHelper.deleteFile(att.file_name));
        promises.push(...attachmentPromises);
      }
  
      // Use Promise.all to wait for all promises to resolve
      await Promise.all(promises);
  
      // Reload after all deletions are done
      setReload(true);
    } catch (error) {
      console.error('Error deleting item and attachments:', error);
      // Handle error as needed
    }
  };

  const handleOnClose = async () => {
    if (currItem.attachments) {
      const promises = currItem.attachments
        .filter((att: Attachment) => att.method === 'create')
        .map((att: Attachment) => attachHelper.deleteFile(att.file_name));
    
      await Promise.all(promises);
    }
    closeModal();
  }

  return (
    <>
      <div className="content px-4 mx-auto">
        <div className="text-lg md:text-2xl font-medium text-Headline mt-6 md:mt-12 mb-6 md:mb-8">公告管理</div>

        <div className='flex flex-col sm:flex-row justify-between items-end gap-4 mb-6 whitespace-nowrap'>
          <div className='hidden sm:block w-28'></div>

          <div className="mx-auto">
            <div className="input-group">
              <div className="input-group-prepend">
                <span id="basic-addon1" className="input-group-text">
                  <img src={searchIcon} alt="" />
                </span>
              </div>
              <input type="text" placeholder="主旨、內容關鍵字搜尋" className="form-control" onChange={(e) => setQuery(e.target.value)} value={query}/>
              <div className="">
                <button type="button" className="flex py-2 px-3 border border-l-0 border-[#ced4da] rounded-r-full" onClick={()=>setReload(true)}>
                  <img src={refreshIcon} alt="" />
                </button>
              </div>
            </div>
          </div>
          <button className='flex justify-center items-center gap-2 px-3 py-2 border border-primary rounded-lg hover:opacity-75' onClick={()=>handleAddItem()}>
            <img src={addIcon} /><div className='text-primary'>新增公告</div>
          </button>
        </div>

        <div className="relative shadow-md sm:rounded-lg">
          <table className="ctable w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="hidden md:table-header-group text-xs text-gray-700 uppercase bg-[#effaf9]">
              <tr>
                <th className="min-w-36">單位</th>
                <th>主旨</th>
                <th>時間</th>
                <th className="min-w-28 text-center">功能</th>
              </tr>
            </thead>
            <tbody>
            {
              allAnnounce.map((item: any) => (
                <tr className="flex md:table-row flex-col border-b hover:bg-gray-100 py-4 md:py-2">
                  <th>{item.unit_name}</th>
                  <td>
                    <a href='javascript:void(0);' onClick={() => handleItemClick(item)} className='hover:underline'>
                      {item.is_top && <span className='text-warning'>【置頂】</span>}{item.title}
                    </a>
                  </td>
                  <td>{buildTimeRange((item.start_at)? item.start_at:'', (item.end_at)? item.end_at:'')}</td>
                  <td className='z-10'>
                    <div className='flex justify-center gap-4'>
                      <button><img src={editIcon} title='edit' onClick={()=>handleEditItem(item)}/></button>
                      <a href='javascript:void(0);' onClick={()=>handleDelItem(item)} className="z-10"><img src={removeIcon} title='del' /></a>
                    </div>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>

      </div>

      <Modal show={isModalOpen} onClose={handleOnClose}>
        <div className='px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
          <div className="text-xl md:text-3xl font-semibold text-Headline text-center mb-4">{(editMode===0)? '新增公告':'修改公告'}</div>

          <form>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-4 mb-6'>
              <div className='flex gap-5'>
                <div>
                  <input id="topy" name="ontop" type="radio" checked={currItem?.is_top} onChange={(e)=>{setCurrItem({...currItem,is_top:e.target.checked})}}/>
                  <label htmlFor="topy" className="text-gray-900 ms-2" >置頂</label>
                </div>
                <div>
                  <input id="topn" name="ontop" type="radio" checked= {!currItem?.is_top} onChange={(e)=>{setCurrItem({...currItem,is_top:!e.target.checked})}}/>
                  <label htmlFor="topy" className="text-gray-900 ms-2" >不置頂</label>
                </div>
              </div>
              <div className='grid md:col-span-2 grid-cols-2 gap-4 md:gap-6'>
                <div className='dateleft flex flex-col'>
                  <label htmlFor="uptime" className='text-gray-900'>上架時間</label>
                  <DatePicker
                    id='uptime'
                    selected={currItem?.start_at}
                    onChange={(date: Date | null) => {handleStartDate(date)}}
                    locale="zhTW"
                    dateFormat="yyyy/MM/dd"
                    className="datepick bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary focus-visible:outline-none w-full p-2.5" />
                </div>
                <div className='dateright flex flex-col'>
                  <label htmlFor="downtime" className='text-gray-900'>下架時間</label>
                  <DatePicker
                    id='downtime'
                    selected={currItem?.end_at}
                    onChange={(date: Date | null) => handleEndDate(date)}
                    locale="zhTW"
                    dateFormat="yyyy/MM/dd"
                    className="datepick bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary focus-visible:outline-none w-full p-2.5" />
                </div>
              </div>
              <div>
                <label htmlFor="unit" className='text-gray-900'>公告單位<span className='text-text text-sm'>(最多7個字)</span></label>
                <input type="text" id="unit" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary focus-visible:outline-none w-full p-2.5" placeholder="公告單位" onChange={(e)=>handleUnitName(e.target.value)} value={currItem.unit_name}/>
              </div>
              <div>
                <label htmlFor="title" className='text-gray-900'>主旨</label>
                <input type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary focus-visible:outline-none w-full p-2.5" placeholder="主旨" value={currItem?.title} onChange={(e)=>handleTitle(e.target.value)}/>
              </div>
              <div className='md:col-span-2 relative'>
                <label htmlFor="content" className='text-gray-900'>內文</label>
                <div className="msg mb-2 h-40">
                  <TextEditor defaultValue={currItem?.content} onChange={content => setCurrItem({...currItem,content})}/>
                </div>
              </div>
              <div className='md:col-span-2 custom-file relative mb-2 mt-self'>
                <div className='text-gray-900'>附件</div>
                <input type="file" id="files" accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.png,.jpg,.jpeg" className='hidden' placeholder="附件" onChange={handleUpload}/>
                <label htmlFor="files" className='flex justify-between bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary focus-visible:outline-none w-full p-4 mb-4'>
                  <span className='text-[#a3a9b5]'>選擇檔案</span>
                  <img src={uploadIcon} alt=""/>
                </label>
              </div>
              <div className='flex flex-col gap-2 md:col-span-2 mt-10'>
                {
                  currItem?.attachments?.map((item:any) => (
                  (item.method==='delete') ? null : 
                  <div className='-indent-6 ms-6'>
                    <img src={fileIcon} alt="" className='inline' />
                    <a href={"/uploads/"+config.uploadDirectiory+"/"+item.file_name} className='hover:opacity-80 hover:underline' target="_blank" rel="noreferrer noopener">
                      {item.source_file_name}
                    </a>
                    <img src={removeIcon} alt="" title='del' className='inline cursor-pointer ms-2' onClick={()=>handleDelFile(item.file_name)} />
                  </div>
                  ))
                }              
              </div>

            </div>
          </form>
        </div >
        <div className="flex justify-end gap-2 px-4 sm:px-6 py-3">
          <button
            className="bg-warning text-white px-3 py-2 rounded-md shadow-sm hover:opacity-90 transition duration-300"
            onClick={()=>handleOnClose()}
          >
            關閉
          </button>
          <button
            className="bg-primary text-white px-3 py-2 rounded-md shadow-sm hover:opacity-90 transition duration-300"
            onClick={()=>handleSave()}
          >
            儲存
          </button>
        </div>


      </Modal >
    </>
  )
}