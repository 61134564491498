
export class servicesHelper
{
    static async getMyAppLists(role: string) {
        const resp = await fetch(`api/application/myapplications/${role}`);
        return await resp.json() || [];
    }

    static async getMyAppListsWithRatingStat(role: string) {
        const resp = await fetch(`api/application/myapps_rating_stat/${role}`);
        return await resp.json() || [];
    }

    static async getOpenAppList() {
        const resp = await fetch(`api/open_services/applications`);
        return await resp.json() || [];
    }

    static gotoService(
        sid: string,
        dsns:string,
        role_type:string,
        child_id:string,
        service_type:string,
        nextUrl:string,        
    ):string {
        let next=nextUrl;
        next=next.replace('{DSNS}', dsns);
        next=next.replace('{ROLE}', role_type || '');
        const url = [
            `api/application/goto/${sid}?`,
            `dsns=${dsns}&`,
            `role_type=${role_type}&`,
            `chlid_id=${child_id}&`,
            `service_type=${service_type}&`,
            `next=${encodeURIComponent(`${next}`)}`,
        ].join('');
        
        return url;
    }
}