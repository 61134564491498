import React, { useEffect, useState } from "react";
import { Accordion, AccordionTitle, AccordionContent, Icon, AccordionTitleProps, Rating, Input, Form, TextArea } from 'semantic-ui-react';
import { CarouselPage } from "../CarouselPage";
import { Modal } from "../../components/Modal";
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import res01 from "../../assets/img/res01.png";
import res02 from "../../assets/img/res02.png";
import res03 from "../../assets/img/res03.png";
import res04 from "../../assets/img/res04.png";
import res05 from "../../assets/img/res05.png";
import res06 from "../../assets/img/res06.png";
import res07 from "../../assets/img/res07.png";
import res08 from "../../assets/img/res08.png";
import res09 from "../../assets/img/res09.png";
import res10 from "../../assets/img/res10.png";

import icon0 from "../../assets/img/icon0.svg";
import icon1 from "../../assets/img/icon1.svg";
import icon2 from "../../assets/img/icon2.svg";
import icon3 from "../../assets/img/icon3.svg";
import star from "../../assets/img/star.svg";
import close from "../../assets/img/close.svg";
import eyeno from "../../assets/img/eyeno.svg";
import eyeyes from "../../assets/img/eyeyes.svg";
import { News } from "../News";
import { servicesHelper } from "../../dal/servicesHelper";
import { settingsHelper } from "../../dal/settingsHelper";
import { useUserContext } from "../../components/auth/userContext";
import { myFavoriteHelper } from "../../dal/myFavoriteHelper";
import { ratingHelper } from "../../dal/ratingHelper";
import {ServiceRating} from "../../components/value_objects"
import {maskChineseName} from "../../modules/utils"
import dayjs from 'dayjs';
import ReactStars from "react-stars";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const favourite = [
  { name: 'LoiLoNote School', img: res01, href: 'https://loilonote.app/login', hicon: icon1, title: '移除我的最愛', content: '授權對象與備註 : 高雄市政府教育局所屬公私立國小、國中、高中職（以校為單位申請與管理。）' },
  { name: 'WebTR差勤', img: res02, href: 'resource', hicon: icon1, title: '移除我的最愛', content: '' },
  { name: '線上預約', img: res03, href: 'repair', hicon: icon1, title: '移除我的最愛', content: '' },
];
const digit = [
  { name: 'LoiLoNote School', img: res01, href: 'https://loilonote.app/login', hicon: icon1, title: '移除我的最愛', content: '授權對象與備註 : 高雄市政府教育局所屬公私立國小、國中、高中職（以校為單位申請與管理。）' },
  { name: 'Adobe Creative Cloud', img: res04, href: 'https://adobe.kh.edu.tw/#/', hicon: icon0, title: '加入我的最愛', content: '授權對象與備註 : 高雄市政府教育局所屬公私立國小、國中、高中職' },
  { name: '威力導演', img: res05, href: 'repair', hicon: icon0, title: '加入我的最愛', content: '授權對象與備註 : 高雄市政府教育局所屬公私立國小、國中、高中職' },
  { name: '作文易學堂', img: res06, href: 'https://www.ezwriting.com.tw/login.aspx', hicon: icon0, title: '加入我的最愛', content: '授權對象與備註 : 高雄市政府教育局所屬公私立國小、國中（使用教育雲端帳號／縣市OpenID登入。）' },
  { name: '中文能力雲端診斷系統', img: res07, href: 'https://cwt.kh.edu.tw/', hicon: icon0, title: '加入我的最愛', content: '' },
];
const resource = [
  { name: 'LoiLoNote School', img: res01, href: 'https://loilonote.app/login', hicon: icon1, title: '移除我的最愛', content: '授權對象與備註 : 高雄市政府教育局所屬公私立國小、國中、高中職（以校為單位申請與管理。）' },
  { name: 'HyRead國語日報知識庫', img: res08, href: 'https://mdnkids.ebook.hyread.com.tw/', hicon: icon0, title: '加入我的最愛', content: '授權對象與備註 : 高雄市政府教育局所屬公私立國小（於學校IP範圍內無需登入即可使用，離開學校IP範圍後使用縣市OpenID登入。）' },
  { name: '線上報修', img: res09, href: 'repair', hicon: icon0, title: '加入我的最愛', content: '' },
  { name: 'HyRead行動學習墊子雜誌庫', img: res10, href: 'https://khedu.ebook.hyread.com.tw/', hicon: icon0, title: '加入我的最愛', content: '授權對象與備註 : 高雄市政府教育局所屬公私立國小、國中、高中職（於學校IP範圍內無需登入即可使用，離開學校IP範圍後使用縣市OpenID登入。）' },
];

type roleInfo = {
  role_type: string,
  role_diaplay_name: string,
  dsns: string,  
}

const role = [
  { id: 1, name: '中正國小 教師' },
  { id: 2, name: '三民國中 教師' },
  { id: 3, name: '三民測試國小 金大寶 家長' },
]

export const Resourced = () => {
  const [myfavorite, setMyFavorite] = useState([]);
  const [myApps, setMyApps] = useState([]);  //登入角色可用服務
  const [roleList, setRoleList] = useState<roleInfo[]>([]);  // 登入帳號的角色清單
  const [activateRole, setActivateRole] = useState<roleInfo>();  // 選擇的角色
  const [serviceTags, setServiceTags] = useState<string[]>([]);  // 服務類別標籤
  const [currApp,setCurrApp] = useState<any>(); // 點選的服務
  const [currAppID,setCurrAppID] = useState<number|null>(); // 點選的服務ID
  const [currAppStat,setCurrAppStat] = useState(
    {
      rating_avg:0,
      rating_count:0,
    }
  ); // 點選的服務評價統計
  const [serviceRatingList,setServiceRatingList] = useState<ServiceRating[]>([]); // 服務評價
  const [currServRating,setCurrServRating] = useState<any>(); //登入者之服務評價
  const [toggleActivate,setToggleActivate] =useState(false) //管理評價之按鈕
  
  const [isInfoModalOpen, setInfoModalOpen] = useState(false); // APP資訊視窗控制項
  const [isRatingModalOpen, setRatingModalOpen] = useState(false); // 評價視窗控制項
  const [modalContent, setModalContent] = useState({ title: '', content: '', img: '' });
  const [editRating, setEditRating] = useState(false) //評價編輯

  const { userInfo } = useUserContext();
  const [reload, setReload] = useState(false);

  // 彙整登入者之所有角色
  const handleRoleList = () => {
    let roleList: roleInfo[] = [];
    const teacherList = userInfo?.teacherDetail;
    const studentList = userInfo?.studentDetail;
    const parentList = userInfo?.parentDetail;
    if (teacherList) {
      teacherList.forEach((item: any) => {
        roleList.push({ role_type: 'teacher', role_diaplay_name: item.school_name + "教師", dsns: item.dsns ||''})
      })
    }
    if (studentList) {
      studentList.forEach((item: any) => {
        roleList.push({ role_type: 'student', role_diaplay_name: item.school_name + "的學生", dsns: item.dsns || ''})
      })
    }
    setRoleList(roleList);
    if (roleList.length > 0) {
      setActivateRole(roleList[0]);
    }
  }
  const handleSelectRole = (e: any) => {
    const foundRole = roleList.find(role => role.role_diaplay_name === e.target.value)
    setActivateRole(foundRole)
  }

  const handleAddMyFavorite = (id: number) => {
    return async () => {
      try {
        await myFavoriteHelper.addMyFavorite(id.toString());
        setReload(!reload);
      } catch (error) {
        console.error('Error adding my favorite:', error);
      }
    }
  }

  const handleRemoveMyFavorite = (id: number) => {
    return async () => {
      try {
        await myFavoriteHelper.removeMyFavorite(id.toString());
        setReload(!reload);
      } catch (error) {
        console.error('Error adding my favorite:', error);
      }
    }
  }

  const loadData = async () => {
    if (activateRole) {
      try {
        const [appList, serviceTag, myFavorite] = await Promise.all([
          servicesHelper.getMyAppListsWithRatingStat(activateRole?.role_type),
          settingsHelper.getGlobalSettingByKey('service_tag'),
          myFavoriteHelper.getMyFavRatingStatByRole(activateRole.role_type)
        ]);

        if (appList) {
          appList.sort((a: any, b: any) => {
            if (a.service_tag === b.service_tag) {
              return a.display_order - b.display_order;
            }
            return a.service_tag.localeCompare(b.service_tag, 'zh-Hant-TW');
          });
        }

        const serviceTags = serviceTag.value.split(',');
        setServiceTags(serviceTags || []);

        setMyFavorite(myFavorite);
        setMyApps(appList);

      } catch (error) {
        console.error('Error loading data:', error);
      }
    }
  }

  const loadServiceRatingList = async(appID:any)=>{
    if(!appID) return
    const sRating=await ratingHelper.getServiceRatingList(appID)
    if(sRating.length>0)
    {
      let total=0;
      sRating.forEach((item:any)=>total+=item.rating)
      const avg=(total/sRating.length).toFixed(1);
      setCurrAppStat({
        rating_avg:parseFloat(avg),
        rating_count:sRating.length
      })

      const userRating=sRating.find((item:any)=>item.uuid===userInfo?.uuid)
      if(userRating)
      {
        setCurrServRating(userRating);
        const filter=sRating.filter((item:any)=>item.uuid!==userInfo?.uuid)
        if(filter.length>0)
        {
          setServiceRatingList([userRating,...filter])
        }
        else
        {
          setServiceRatingList([userRating])
        }                
      }
      else
      {
        setCurrServRating({
          rating_id:null,
          uuid:(userInfo?.uuid) ? userInfo?.uuid:'',
          member_name:'',
          rating:5,
          title:'',
          content:'', 
          updated_at:null,
          activate:true
        });
        setServiceRatingList(sRating)
      }
    }
    else
    {
      setCurrServRating({
        rating_id:null,
        uuid:(userInfo?.uuid) ? userInfo?.uuid:'',
        member_name:'',
        rating:5,
        title:'',
        content:'', 
        updated_at:null,
        activate:true
      });
      setServiceRatingList([])
      setCurrAppStat({
        rating_avg:0,
        rating_count:0
      })
    }
  }  
  
  const openInfoModal = (item:any) => {
    setModalContent({ title: item.name, content: item.service_desc, img: item.logo_url });
    setInfoModalOpen(true);
  };  
  
  const openRatingModal = (appID:any) => {
    setCurrAppID(appID)
    loadServiceRatingList(appID);
    setRatingModalOpen(true); 
  };
  
  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };
  
  const closeRatingModal = () => {
    setEditRating(false);
    setCurrAppID(null);
    setRatingModalOpen(false);
  };   
  
  const handleEditRating = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, titleProps: AccordionTitleProps) => {
    setEditRating(!editRating)
  }
  
  const dispRatingStar=(rating:string)=>{
    const ratingNum=parseFloat(rating);
    const maxStars = 5;
    const fullStars = Math.floor(ratingNum);
    const hasHalfStar = ratingNum - fullStars >= 0.5;
    const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0);
    return (
      <>
          {[...Array(fullStars)].map((_, index) => (
              <FaStar key={`full-${index}`}/>
          ))}
          {hasHalfStar && <FaStarHalfAlt />}
          {[...Array(emptyStars)].map((_, index) => (
              <FaRegStar key={`empty-${index}`} />
          ))}
      </>
    );
  }

  const handleRatingTitle=(e: React.ChangeEvent<HTMLInputElement>)=>{
    e.preventDefault();
    setCurrServRating({
      ...currServRating,
      title:e.target.value
    })
  }

  const handleRatingContent=(e: React.ChangeEvent<HTMLTextAreaElement>)=>{
    e.preventDefault();
    setCurrServRating({...currServRating,content:e.target.value})
  }

  const handleRatingChange=(newRating:number)=>{
    const updateRating= newRating===1 && currServRating.rating===1 ? 0 : newRating;
    setCurrServRating({...currServRating,rating:updateRating})
  }
  
  const handleAddRating=async()=>{
    if(currServRating.title===''){
      showMessage('評價標題','評價標題不可為空白')
      return
    }
    if(currServRating.rating_id)
    {
      const resp=await ratingHelper.updateServiceRating(currServRating.rating_id,currServRating.rating,currServRating.title,currServRating.content)
      console.log('updateRating',resp)
      if(resp)
      {
        setReload(true)
        setEditRating(false)    
        loadServiceRatingList(currAppID);
      }
    }
    else
    {
      if(currAppID)
      {
        const resp=await ratingHelper.addServiceRating(currAppID.toString(),currServRating.rating,currServRating.title,currServRating.content)
        if(resp)
        {
          setReload(true)
          setEditRating(false)
          loadServiceRatingList(currAppID);
        }

      }
    }  
  }
  const showMessage = (errTitle:string,errText:string) => {
    withReactContent(Swal).fire({
      title: errTitle,
      text: errText,
      showDenyButton: false,
      showConfirmButton: true,
      confirmButtonText: "確定",
      showCancelButton: false,
      reverseButtons: false,
      customClass: {
        confirmButton: 'bg-warning',
      }
    })
  }

  const handleActivate=async(serviceRating:any)=>{
    const resp=await ratingHelper.setActServiceRating([serviceRating.rating_id],!serviceRating.activate)
    if(resp)
    {
      setReload(true)
      setEditRating(false)
      loadServiceRatingList(currAppID);
    }
  }

  const handleGotoService=(app:any):string=>{    
      if(app.sso_url)
      {
        return servicesHelper.gotoService(app.id.toString(),activateRole?.dsns || '',activateRole?.role_type || '','',app.service_type,app.sso_url)        
      }
      else
      {
        return ''
      }    
  }
  useEffect(() => {
    if (userInfo) {
      handleRoleList();
    }
  }, [userInfo]);

  useEffect(() => {
    if (activateRole) {
      loadData();
    }
  }, [activateRole])

  useEffect(() => {
    if (reload) {
      loadData();
      setReload(false);
    }
  }, [reload])

  return (
    <>
      <div>
        <CarouselPage />
      </div>
      <div className="content px-4 mx-auto pt-2">
        <div className="text-xl md:text-9 font-medium text-Headline mt-12 md:mt-16 mb-6 md:mb-8">我的最愛</div>
        <div className="grid items-start grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 text-Headline md:gap-8">
          {myfavorite.map((item: any) => (
            <div key={item.name} className="appcard resbox cursor-pointer" onClick={()=>handleGotoService(item)}>
              <img src={item.logo_url} alt={item.name}/>
              <div className="flex text-xs gap-1 ms-2 cursor-pointer mt-2" onClick={() => openRatingModal(item.id)}>{(item.rating_avg)? item.rating_avg:'0'}{dispRatingStar((item.rating_avg)? item.rating_avg:'0')}</div> 
              <a href={handleGotoService(item)} target="_blank" rel="noreferrer noopenner"
                className="text-sm md:text-lg font-medium text-center hover:text-primary m-[14px] mt-0 clamp1 cursor-pointer">{item.name}
              </a>
              <div className="hibut">
                <div className="wrap">
                  <div className="max-w-[15%]">
                    <img src={icon1} alt='移出我的最愛' title={item.name} onClick={handleRemoveMyFavorite(item.id)} />
                  </div>
                  <div className="max-w-[15%]" onClick={() => openInfoModal(item)}>
                    <img src={icon2} alt="更多資訊" title="更多資訊" />
                  </div>
                  <a href={handleGotoService(item)} target="_blank" rel="noreferrer noopenner" className="max-w-[15%] cursor-pointer">
                    <img src={icon3} alt="" title="進入服務說明" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        {
          serviceTags.map((item, index) => (
            <>
              {(index === 0) ?
                <>
                  <div className="flex justify-between items-center mt-12 md:mt-16 mb-6 md:mb-8">
                    <div className="text-xl md:text-9 font-medium text-Headline">{item}</div>
                    <form className="">
                      <select id="countries" onClick={(e) => handleSelectRole(e)} className="bg-[#6cd5cc0f] border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary focus-visible:outline-none block w-full p-1">
                        <>
                          {
                            roleList.map((item) => (
                              (item.role_diaplay_name === activateRole?.role_diaplay_name) ?
                                <option value={item.role_diaplay_name} selected >{item.role_diaplay_name}</option> :
                                <option value={item.role_diaplay_name} >{item.role_diaplay_name}</option>
                            ))
                          }
                        </>
                      </select>
                    </form>
                  </div>
                </> :
                <>
                  {myApps.filter((app: any) => app.service_tag === item).length > 0 &&
                  <div className="text-xl md:text-9 font-medium text-Headline mt-12 md:mt-16 mb-6 md:mb-8">{item}</div>}
                </>
              }
              <div className="grid items-start grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 text-Headline md:gap-8">
                {
                  myApps.filter((app: any) => app.service_tag === item).map((appItem: any) => (
                    <div key={appItem.name} className="appcard resbox cursor-pointer" onClick={()=>handleGotoService(item)}>
                      <img src={appItem.logo_url} alt={appItem.name} />
                      <div className="flex text-xs gap-1 ms-2 cursor-pointer mt-2" onClick={() => openRatingModal(appItem.id)}>{(appItem.rating_avg)? appItem.rating_avg:'0'}{dispRatingStar((appItem.rating_avg)? appItem.rating_avg:'0')}</div>                 
                      <a href={handleGotoService(appItem)} target="_blank" rel="noreferrer noopenner"
                        className="text-sm md:text-lg font-medium text-center hover:text-primary m-[14px] clamp1 cursor-pointer">{appItem.name}
                      </a>
                      <div className="hibut">
                        <div className="wrap">
                          <div className="max-w-[15%]">
                            <img src={icon0} alt='加到我的最愛' title='加到我的最愛' onClick={handleAddMyFavorite(appItem.id)} />
                          </div>
                          <div className="max-w-[15%]" onClick={() => openInfoModal(appItem)}>
                            <img src={icon2} alt="更多資訊" title="更多資訊" />
                          </div>
                          {(appItem.entry_url) ?
                            <a href={appItem.entry_url} target="_blank" rel="noreferrer noopenner" className="max-w-[15%] cursor-pointer">
                              <img src={icon3} alt="" title="進入服務說明" />
                            </a> : <></>
                          }
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ))
        }
      </div >

      <Modal show={isInfoModalOpen} onClose={closeInfoModal}>
        <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className='flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start gap-4'>
            <img src={modalContent.img} alt="" className='w-28' />
            <div>
              <h1 className="text-xl md:text-3xl font-semibold text-Headline">{modalContent.title}</h1>
              <p className="mt-2 text-base md:text-lg">{modalContent.content}</p>
            </div>
          </div>
        </div>

        {/* <div className="px-4 sm:px-6">內容</div> */}
        <div className="flex justify-end gap-4 px-4 sm:px-6 py-3">
          <button
            className="bg-warning text-white px-3 py-2 rounded-md shadow-sm hover:opacity-90 transition duration-300"
            onClick={closeInfoModal}
          >
            關閉
          </button>
        </div>
      </Modal>

      <Modal show={isRatingModalOpen} onClose={closeRatingModal}>
        <div className="mt-4 me-4">
          <img src={close} alt="" className="cursor-pointer ms-auto" onClick={closeRatingModal} />
        </div>
        <div className="px-4 py-6 md:px-16">

          <Accordion>

            <div className='flex flex-wrap justify-between items-center gap-4'>
              <div className="flex items-center gap-4">
                <div className="text-3xl md:text-6xl text-secondary font-black" style={{ fontFamily: 'Arial Black' }}> 
                  {currAppStat.rating_avg.toFixed(1)}                 
                </div>
                <div>
                  {/*<Rating icon='star' defaultRating={currAppStat.rating_avg} maxRating={5} disabled className="text-base md:!text-lg" />*/}
                  <ReactStars count={5} value={currAppStat.rating_avg} size={24} color2={"#ffd700"} color1={"#E0E0E0"} half={false} edit={false}  />

                  <div className="text-sm md:text-base">{currAppStat.rating_count}則評論</div>
                </div>
              </div>
              <div className="flex ms-auto gap-4">
                <AccordionTitle active={editRating} onClick={handleEditRating} className="">
                  <button className='bt-green md:text-2xl font-normal w-24 md:w-36 whitespace-nowrap py-2 md:py-[9px] hover:opacity-80 '>撰寫評論</button>
                </AccordionTitle>
                {
                  userInfo?.isAdmin &&
                  <button className='bt-red md:text-2xl font-normal w-24 md:w-36 whitespace-nowrap py-2 md:py-[9px] hover:opacity-80 ' onClick={()=>setToggleActivate(!toggleActivate)}>管理評論</button>
                }
              </div>
            </div>
            <AccordionContent active={editRating}>
              <div className="flex flex-col gap-4 md:text-2xl pt-6">
                <div className="flex items-center w-fit gap-2">
                  <div>評價分數</div>
             {/*}     <Rating icon='star' defaultRating={(currServRating) ? currServRating.rating:0} value={(currServRating) ? currServRating.rating:0}
                  maxRating={5} className="!text-lg md:!text-2xl" />*/}
              <ReactStars
                count={5}
                value={(currServRating) ? currServRating.rating:0}
                size={24}
                color2={"#ffd700"} // Filled star color
                color1={"#E0E0E0"} // Empty star color                
                half={false} // Allow half-star ratings
                onChange={handleRatingChange}
              />
                </div>
                <div className="flex gap-2">
                  <div className="shrink-0">評價標題</div>
                  <Input placeholder='' className="text-base w-full" value={(currServRating)? currServRating.title:''} onChange={handleRatingTitle}/>
                </div>
                <div className="flex items-center gap-2 w-full">
                  <div className="shrink-0">評價內容</div>
                  <Form className="w-full">
                    <TextArea placeholder='' className="text-base w-full h-44" value={(currServRating)? currServRating.content:''} onChange={handleRatingContent}/>
                  </Form>
                </div>
                <button className="bt-green text-base md:text-2xl font-normal w-16 md:w-24 whitespace-nowrap py-2 md:py-[9px] hover:opacity-80 mb-4 ms-auto" onClick={()=>handleAddRating()}>送出</button>
                <hr />
              </div>
            </AccordionContent>
          </Accordion>
          <div>
            {
              serviceRatingList.map((sr,index)=>(
                (userInfo?.isAdmin || (!userInfo?.isAdmin && sr.activate) || (userInfo?.uuid===sr.uuid))  ? (
                <div key={index} className="flex my-6">
                  <div className={(sr.activate || userInfo?.uuid===sr.uuid) ? "bg-[#f7f3fc] p-6 min-w-[276px] md:min-w-[680px] lg:min-w-[822px]":"bg-[#f7f3fc] p-6 opacity-45 min-w-[276px] md:min-w-[680px] lg:min-w-[822px]"}>
                    <div className="flex justify-between items-center mb-4 md:mb-8">
                      <ReactStars count={5} value={sr.rating} size={24} color2={"#ffd700"} color1={"#E0E0E0"} half={false} edit={false} />

                      {/*<Rating icon='star' defaultRating={sr.rating} maxRating={5} disabled className="!text-lg md:!text-2xl w-auto" />*/}
                      <div className="text-right">
                        <div>{maskChineseName(sr.member_name)}</div>
                        <div>{dayjs(sr.updated_at).format("YYYY-MM-DD")}</div>
                      </div>
                    </div>
                    <div className="text-lg md:text-2xl font-medium mb-4 md:mb-9 max-w-xs md:max-w-md lg:max-w-lg truncate">{sr.title}</div>
                    <div className="text-lg md:text-2xl ">{sr.content}</div>
                  </div>
                  {
                    (toggleActivate) ? 
                    <div onClick={()=>handleActivate(sr)} className={(sr.activate)? "flex items-center flex-shrink-0 bg-warning w-12 md:w-20" : "flex items-center flex-shrink-0 bg-cyan-600 w-12 md:w-20"} >
                      <img src={(sr.activate)? eyeno:eyeyes} alt="" className="mx-auto" />
                    </div> :                    
                    <div className={(sr.activate || userInfo?.uuid===sr.uuid)? "flex items-center flex-shrink-0 bg-[#f7f3fc] w-12 md:w-20":"flex items-center flex-shrink-0 bg-[#f7f3fc] opacity-45 w-12 md:w-20"}>
                    
                   </div>
                  }
                  
                </div> ) : null
              )                         
            )}
          </div>
          <div>

          </div>
        </div>
      </Modal >
    </>
  );
};
