// 線上報修頁面
export const Repair = () => {
  return (
    <div className="mb-16">
      <div className="">
        <div className="flex items-center staticBanner bg-[#F0BEBE] relative">
          <div className="staticBannerContent">
            <img
              src="/images/repairBanner.svg"
              alt="banner"
              className="staticBannerImg"
            />
            <div className="text-sm sm:text-lg md:text-3xl absolute w-[40%] max-w-[400px] translate-x-[59%] max-h-full overflow-auto">
              <h2 className=" font-black text-primaryDark">
                數位學習設備報修流程
              </h2>
              <p className="text-secondaryDark mt-[0.5em] text-[0.65em] leading-normal">
                請依照報修流程填妥相關申請，待工程師收到後將盡速為您聯絡並進行檢修流程。
              </p>
            </div>
          </div>
        </div>
        <div className="staticSection">
          <div className="sectionContent">
            <h3 className="sectionTitle text-Headline">報修流程</h3>
            <div className="space-y-10 md:space-y-16">
              <div className="md:grid md:grid-cols-3 items-center gap-10 space-y-6">
                <div className="col-span-2">
                  <img
                    src="/images/repair-step-01.jpg"
                    alt="repair"
                    className="w-auto max-w-full"
                  />
                </div>
                <div className="col-span-1 text-base lg:text-2xl text-text">
                  <div className="bgImg-text-bubble ">
                    <p className="">
                      步驟一：進入
                      <a
                        href="https://portal.kh.edu.tw/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="underline text-nowrap">
                          高雄市資訊入口網
                        </span>
                      </a>
                    </p>
                    <p className="">【行政服務】＞【線上報修】</p>
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-3 items-center gap-10 space-y-6">
                <div className="col-span-2">
                  <img
                    src="/images/repair-step-02.jpg"
                    alt="repair"
                    className="w-auto max-w-full"
                  />
                </div>
                <div className="col-span-1 text-base lg:text-2xl text-text">
                  <div className="bgImg-text-bubble ">
                    <p className="">步驟二：選擇要報修的廠商</p>
                  </div>
                  <div className="bgImg-text-bubble mt-4 bubble2">
                    <div className="text-sm font-normal">
                      晶盛：iPad
                      <br />
                      國眾：ASUS Chromebook、ASUS觸控筆電、Samsung平板、HP平板
                      <br />
                      宏碁：Acer Chromebook
                      <br />
                      遠傳：Surface Go 3<br />
                      頂陽（快易通）：Surface Go 2、ASUS、iPad、Acer
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-3 items-center gap-10 space-y-6">
                <div className="col-span-2">
                  <img
                    src="/images/repair-step-03.jpg"
                    alt="repair"
                    className="w-auto max-w-full"
                  />
                </div>
                <div className="col-span-1 text-base lg:text-2xl text-text">
                  <div className="bgImg-text-bubble ">
                    <p className="">步驟三：完成報修表單後送出</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="staticSection">
          <div className="sectionContent">
            <h3 className="sectionTitle text-Headline md:absolute">聯絡我們</h3>
            <div className="w-full flex flex-col-reverse md:flex-row justify-between gap-x-16 gap-y-6 items-center text-sm sm:text-base lg:text-2xl">
              <p className="text-text text-justify w-full md:grow">
                報修表單送出後，負責廠商的工程師會與您聯絡，若有任何疑問，歡迎與我們聯絡。
                <br />
                <br />
                晶盛：(07)726-0089 #161～167
                <br />
                國眾：(07)726-0089 #168、169
                <br />
                宏碁：(07)338-8386 #216
                <br />
                遠傳：(07)726-0039
                <br />
                頂陽（快易通）：(07)726-0089 #21、22
              </p>
              <div className="w-3/4 md:w-5/12 shrink-0">
                <img
                  src="/images/repairImg01.svg"
                  alt=""
                  className="w-full object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
