import { useEffect, useState } from 'react';
import back from '../../assets/img/back.svg';
import fileIcon from '../../assets/img/file.svg';
import { annoHelper } from '../../dal/annoHelper';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { buildTimeRange } from '../../modules/utils';
import config from '../../SysConfig.json';

export const AnnounceContent = () => {
  const location = useLocation();
  const { item } = location.state || {};
  console.log('item in content', item);

  return (
    <>
      <div className="content px-4 mx-auto">
        <div className='flex justify-between items-end mt-6 md:mt-12 mb-6 md:mb-8'>
          <a href='/main/announce' className='flex items-center gap-2 hover:opacity-75'><img src={back} alt="" />返回</a>
          <div className="text-lg md:text-2xl font-medium text-Headline">公告訊息</div>
          <div className='w-16'></div>
        </div>
        <div className="relative sm:rounded-lg p-4 md:p-6" style={{ boxShadow: '#efefef 3px 3px 10px 5px' }}>
          <div className='font-medium'>{item.is_top && <span className='text-warning'>【置頂】</span>}{item.title}</div>
          <div className='flex flex-col sm:flex-row justify-start sm:justify-between text-sm my-3'>
            <div>{buildTimeRange((item.start_at)? item.start_at:'', (item.end_at)? item.end_at:'')}</div>
            <div>點閱數：{item.hits}</div>
          </div>
          <div className='flex flex-col sm:flex-row justify-start sm:justify-between text-sm'>
            <div>{item.unit_name}</div>
            <div>最後更新：{item.creater_name} 於 {dayjs(item.updated_at).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>

          <div className='flex flex-col gap-2 md:col-span-2 bg-slate-50 p-4 my-4'>
            {(item.attachments) &&
              item.attachments.map((att:any) => {
                return (
                <div className='-indent-6 ms-6'>
                  <img src={fileIcon} alt="" className='inline' />
                  <a href={"/uploads/"+config.uploadDirectiory+"/"+att.file_name} className='hover:opacity-80 hover:underline' target="_blank" rel="noreferrer noopener">
                      {att.source_file_name}
                  </a>
                </div>
                )
              })
            }  
          </div>

          <div className='break-all' dangerouslySetInnerHTML={{ __html: item.content }}/>

        </div>

      </div>

    </>
  )
}