export class settingsHelper
{
    static async getGlobalSettingByKey(key: string) {
        const resp = await fetch(`api/setting/get_setting/${key}`);
        return await resp.json() || [];
    }
    static async getOpenGlobalSettingByKey(key: string) {
        const resp = await fetch(`api/open_setting/get_setting/${key}`);
        return await resp.json() || [];
    }
}