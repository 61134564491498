import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo.svg";

export const Footer = () => {
  return (
    <>
      <div className="h-3 w-full bg-primary"></div>
      <div className="bg-[#E4DFDC] py-9 px-4 text-center text-[#727272]">
        <div className="flex flex-col lg:flex-row justify-center md:justify-between items-center md:items-start lg:items-center max-w-[1260px] mx-auto gap-y-6 pb-9">
          <div className="w-auto lg:w-1/4"><img src={logo} /></div>
          <div className="flex flex-col md:flex-row justify-center md:justify-between items-center max-w-[840px] w-full lg:w-2/3 gap-y-6 mx-auto lg:mx-0">
            <div className="text-center leading-[30px]">
              <NavLink to="/unit">
                <div>單位介紹</div>
              </NavLink>
              <NavLink to="/plan">            
                <div>計劃專區</div>
              </NavLink>              
              <div>常見問題</div>
              <div>資通安全及隱私政策</div>
            </div>
            <div className="text-center leading-[30px]">
              <NavLink to="/info">
                <div>研習資訊</div>
              </NavLink>
              <NavLink to="/main">              
                <div>教學資源</div>
              </NavLink>
              <NavLink to="/repair">
                <div>線上報修</div>
              </NavLink>
              <NavLink to="/procurement">
                <div>軟體採購</div>
              </NavLink>
            </div>
            <div className="">
              <div>聯絡我們</div>
              <div>07-7260089</div>
              <div>kbdo@gm.kh.edu.tw</div>
              <div>週一至週五上午八點至下午五點﹙上班時間﹚</div>
            </div>
          </div>
        </div>
        <hr className="border-[#72727266] mx-auto" />
        <div className="mt-9">Copyright © 2024 高雄市政府教育局 All right reserved</div>
      </div>
    </>
  )

}