import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";
import UserContext from "../components/auth/userContext";
import { Home } from "./Home";

export const IndexPage = () => {
  return (
    <>
    <UserContext>
      <Header />
      <div>
        <Outlet />        
      </div>
      <Footer />
    </UserContext>
    </>
  )
}