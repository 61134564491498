import { createBrowserRouter } from "react-router-dom";
import { IndexPage } from "./pages/index";
import { ErrorPage } from "./pages/ErrorPage";
import { Home } from "./pages/Home";
import { Unit } from "./pages/Unit";
import { Info } from "./pages/Info";
import { Resource } from "./pages/Resource";
import { Repair } from "./pages/Repair";
import { Procurement } from "./pages/Procurement";
import { Plan } from "./pages/Plan";
import { Login } from "./pages/Login";
import { MainPage } from "./pages/admin/main";
import { Resourced } from "./pages/admin/Resourced";
import { Announce } from "./pages/admin/Announce";
import { AnnounceContent } from "./pages/admin/AnnounceContent";
import { NewsView } from "./pages/NewsView";

export const useRouter = () => {
  return createBrowserRouter([
    {
      path: "/",
      element: <IndexPage />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "unit",
          element: <Unit />,
        },
        {
          path: "info",
          element: <Info />,
        },
        {
          path: "resource",
          element: <Resource />,
        },
        {
          path: "repair",
          element: <Repair />,
        },
        {
          path: "procurement",
          element: <Procurement />,
        },
        {
          path: "plan",
          element: <Plan />,
        },
        {
          path: "newsview",
          element: <NewsView />,
        },
      ]
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "main",
      element: <MainPage />,
      children: [
        {
          index: true,
          element: <Resourced />,
        },
        {
          path: "announce",
          element: <Announce />,
        },
        {
          path: "content",
          element: <AnnounceContent />,
        },
      ]
    },
  ]);
}
