// 單位介紹頁面
import React from "react";
import { StaffData, Staff } from "./staff-data";
export const Unit = () => {
  const tabsData = [
    {
      id: "tab1",
      label: "行政推動",
      imgSrc: "/images/unitImg02.svg",
      items: [
        "召開工作會議，並依會議討論主題邀請合適與會人員。",
        "督導每校每年至少辦理1場數位學習模式之公開觀議課。",
        "了解學校學習載具使用情形，彙整學校數位學習平臺使用時數，上傳至教育部指定系統。",
        "辦理計畫跨校交流、成果展等推廣活動及辦理相關獎勳措施。",
        "本市學校依五大行政區，共設置21所恆星學校（重點學校）和17所校長科技領導學校，負責各分區計畫學校推動溝通及執行項目轉達，協助建立學校社群、推廣數位學習、辦理教師研習及成效評估等工作。",
        "辦理學生數位素養相關推廣活動，鼓勵學校參加教育部相關推廣活動。",
        "管控計畫進度及成果蒐集，配合教育部行政作業（例如建立各校載具分配資料、教學軟體及數位內容教學應用情形調查、訊息轉知、經費撥付與結報等），依限提月報資料（管考系統填報）與成果報告。",
        "配合教育部委託計畫團隊之輔導及相關工作，包含參與全國性活動。",
      ],
    },
    {
      id: "tab2",
      label: "輔導增能",
      imgSrc: "/images/unitImg06.svg",
      items: [
        "組成輔導團隊辦理到校輔導，了解學校學習載具管理及使用情形、教師教學與學生學習情形、執行困難與問題，提供必要協助與輔導。",
        "輔導學校建立數位教學之科技領導與線上教學運作機制。",
        "輔導教師運用學習載具進行科技輔助自主學習、學習扶助、數位教學或線上教學等數位學習。",
        "辦理教師數位學習增能（數位學習工作坊(一)及(二)、數位素養增能培訓、資訊負責人員增能研習等）。",
      ],
    },
    {
      id: "tab3",
      label: "成效評估",
      imgSrc: "/images/unitImg07.svg",
      items: [
        "本市將透過關懷訪視、競賽辦理、系統數據蒐集及跨校交流等方式，以確保受補助之軟硬體資源皆被充分應用。",
        "整合規劃與建置本市學習成效評估機制。",
      ],
    },
    {
      id: "tab4",
      label: "資訊與網路",
      imgSrc: "/images/unitImg08.svg",
      items: [
        "本市優先協助學校網路規劃與調校、硬體操作、數位學習平臺使用、安排技術人力入校協助等，並協助本計畫補助之資訊軟硬體資安維護等。",
        "於疫情居家教學與學習時，提供親師生軟硬體技術與操作諮詢服務。",
        "協助教育部「推動中小學數位學習精進方案」BYOD、THSD等數位學習相關計畫。",
      ],
    },
  ];

  return (
    <div className="mb-16">
      <div className="flex items-center staticBanner bg-[#eac7a3] relative">
        <div className="staticBannerContent">
          <img
            src="/images/unitBanner.svg"
            alt="banner"
            className="staticBannerImg"
          />
          <div className="text-base sm:text-lg md:text-3xl absolute w-[40%] max-w-[400px] -translate-x-1/4 max-h-full overflow-auto">
            <h2 className=" font-black text-primaryDark">我們的使命與價值</h2>
            <p className="text-secondaryDark mt-[0.5em] text-[0.65em] leading-normal">
              為全面推動中小學數位學習精進方案，協助學校提高數位教學與學習品質及成效，高雄市政府教育局成立數位學習推動辦公室。
            </p>
          </div>
        </div>
      </div>
      <div className="staticSection">
        <div className="sectionContent">
          <h3 className="sectionTitle text-Headline">緣起與背景</h3>
          <div className="md:flex gap-6 items-center text-sm sm:text-base lg:text-2xl">
            <img
              src="/images/63186c0113a01.jpg"
              alt=""
              className="w-full md:w-1/2"
            />
            <p className="text-text mt-4 text-justify leading-normal">
              高雄市政府教育局近年來致力打造智慧校園，充實資訊軟硬體基礎設施、強化數位學習並積極推動教育雲服務，協助教師善用雲端資源、結合行動科技、創新教學方法，至今連續8年榮獲教育部縣市資訊教育評比「特優」，成績為全台之冠，展現多年深耕資訊教育成果與績效；在推動數位學習教學不遺餘力，110年共6校入選教育部中小學數位學習深耕計畫，校數也為全國第一。
            </p>
          </div>
          <div className="md:flex gap-6 items-center text-sm sm:text-base lg:text-2xl">
            <p className="text-text mt-4 text-justify leading-normal">
              為全面推動中小學數位學習精進方案，協助學校提高數位教學與學習品質及成效，高雄市政府教育局成立數位學習推動辦公室。
              辦公室主要業務為：定時召開工作會議等行政推動工作、督導高雄市各校辦理公開觀議課、了解學校學習載具使用情形、辦理計畫跨校交流活動、規劃設置重點學校、輔導與增能、辦理學生數位素養相關推廣活動、成效評估、協助資訊與網路及配合教育部委託計畫團隊之輔導等。
              期許該辦公室能把更豐富的知識帶進教室，縮短城鄉學習差距，提升學子的國際視野！
            </p>
            <img src="/images/unitImg01.svg" alt="" className="w-0 md:w-1/3" />
          </div>
        </div>
      </div>
      <div className="staticSection unitSection1">
        <div className="sectionContent">
          <h3 className="sectionTitle text-Headline md:absolute">任務與目標</h3>
          <div className="tabs sm:pt-3 md:pt-6">
            {tabsData.map((tab, index) => (
              <React.Fragment key={tab.id}>
                <input
                  type="radio"
                  className="tabs__radio"
                  name="tabs-example"
                  id={tab.id}
                  defaultChecked={index === 0}
                />
                <label
                  htmlFor={tab.id}
                  className="tabs__label text-sm sm:text-base lg:text-2xl"
                >
                  {tab.label}
                </label>
                <div className="tabs__content md:min-h-[736px] xl:min-h-[600px]">
                  <div className="h-full flex flex-col md:flex-row justify-center pt-6 gap-3 items-center text-sm sm:text-base md:text-xl lg:text-2xl">
                    {tab.imgSrc && (
                      <img
                        src={tab.imgSrc}
                        alt=""
                        className="w-3/4 sm:w-1/2 md:w-1/3 aspect-square"
                      />
                    )}
                    <ul className="list-decimal text-text ps-[2em] leading-normal space-y-1">
                      {tab.items.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="staticSection">
        <div className="sectionContent">
          <h3 className="sectionTitle text-Headline">組織架構</h3>
          <div className="w-full flex flex-col md:flex-row gap-8  items-center justify-between">
            <img
              src="/images/logoBored.svg"
              alt=""
              className="w-1/4 md:w-[16%]"
            />
            <img
              src="/images/unitImg05.svg"
              alt=""
              className="w-full md:w-[80%] object-contain"
            />
          </div>
        </div>
      </div>
      <div className="staticSection">
        <div className="sectionContent">
          <h3 className="sectionTitle text-Headline">職掌分工</h3>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-6 justify-evenly">
            {StaffData.map((staff: Staff, index: number) => (
              <div
                key={index}
                className={`workCard relative text-base cardType-${staff.cardType}`}
              >
                <div className="workCardLeft text-white text-sm leading-normal space-y-1">
                  <h3 className="text-xl">{staff.name}</h3>
                  <p>{staff.role}</p>
                  <p>{staff.title}</p>
                  <p>{staff.phone}</p>
                  <p className="break-words">{staff.email}</p>
                </div>
                <div className="workCardRight absolute right-0 w-1/2 hover:w-full text-sm leading-normal">
                  <ul className="max-h-full absolute p-2 list-disc ps-[1.8em] text-left overflow-x-hidden  space-y-1">
                    {staff.workItem.map((item, idx) => (
                      <li key={idx} className="break-words">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
