import { CarouselPage } from "./CarouselPage";
import backIcon from '../assets/img/back.svg';
import { useLocation } from 'react-router-dom';
import { buildTimeRange } from '../modules/utils';
import config from '../SysConfig.json';
import dayjs from 'dayjs';

export const NewsView = () => {
  const location = useLocation();
  const { item } = location.state || {};
  console.log('item in content', item);
  return (
    <div>
      <CarouselPage />
      <div className='content text-Headline px-4 mx-auto'>
        <div className='text-base lg:text-4xl w-fit border-b-4 border-primary font-medium mt-6 mb-4 lg:mt-16 lg:mb-12 p-1 lg:p-3 mx-auto'>最新消息</div>
        <div className='border-[3px] border-primary custom-shadow p-8 pt-4 lg:px-10 lg:py-8'>
          <div>
            <div className='flex justify-between items-center text-sm lg:text-2xl gap-4 my-4'>
              <div className='flex items-center gap-5 lg:gap-10'>
                <div className='bt-yellow flex-shrink-0 w-[96px] lg:w-[152px] py-2'>{item.unit_name}公告</div>
                <div className='clamp2'>{item.title}</div>
              </div>
              <div className='hidden lg:block'>{buildTimeRange((item.start_at)? item.start_at:'', (item.end_at)? item.end_at:'')}</div>
            </div>
            <hr className="my-9" />
            <div className="text-sm lg:text-2xl" dangerouslySetInnerHTML={{ __html: item.content }}>
            </div>
              {(item.attachments) &&
                item.attachments.map((att:any) => {
                  return (
                  <div className='-indent-6 ms-6'>
                    <a href={"/uploads/"+config.uploadDirectiory+"/"+att.file_name} className='hover:opacity-80 hover:underline' target="_blank" rel="noreferrer noopener">
                        {att.source_file_name}
                    </a>
                  </div>
                  )
                })
              }
            <a 
               href="#" 
               onClick={(e) => { 
                 e.preventDefault(); 
                 window.history.back();
               }}
              className='flex justify-end items-center gap-2 hover:opacity-75 text-primary text-sm lg:text-2xl mt-20'>
              <img src={backIcon} alt="" className="w-4 lg:w-6" />返回
            </a>

          </div>
        </div>
      </div>

      <div className="p-7 lg:p-16"></div>
    </div >
  );
};
