import React, { useEffect, useState, createContext, useContext } from "react";
import { useQueryString } from "../hooks";
import { useNavigate } from "react-router-dom";

export const UserInfoContext = createContext<UserInfoCtx>({
  userInfo: null,
  logout: async () => {}  
});

// console.log('before UserValidation')

function UserContext({ children }: UserContextProps) {
//  const queryString = useQueryString();
//  const code = queryString.get("code");
//  const dsns = queryString.get("dsns");
//  const role = queryString.get("role");

  // console.log({ action: 'enter UserValidation', code, dsns, role })

  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  /** 取得 server 的 session 中的使用者資料 */
  const getUserInfo = async () => {
   // console.log(" getUserInfo() ....");
    try {
      const chk = await fetch("/api/auth/check_alive");
      const chkData = await chk.json();
      console.log('chkdata',chkData);
      if(chkData.alive===false)
      {
        setUserInfo(null);
     //   window.location.href = "/";
     //   navigate("/");
        return;
      }
      const resp = await fetch("/api/auth/userinfo");
      const data = await resp.json();
      console.log('userinfo',data);
      if (!data.uuid) // 未登入
      {        
        setUserInfo(null);
      } else {
        setUserInfo(data);
    /*    if (data.roleType === "student" || data.roleType === "parent") {
          navigate("student");
        } else if (data.roleType === "teacher") {
          // console.log({ data })
          if (!data.isAdmin) {
            navigate("teacher"); 
          } else {
            console.log( { isAdmin: data.isAdmin })
            if ( window.on1CampusWebView) {
              navigate("teacher");  // 行動裝置不處理管理者功能
            } else {
              navigate("teacher/menu");   // 桌機瀏覽器才顯示管理者功能
            }
          }
        } else {
          throw { error: 'unauthorized!' }
        }*/
      }
    } catch (ex) {
      //
      console.log({ ex });
      //navigate("auth/not-signin");
    }
  };

  /** 由 web 或 app 開啟時呼叫 */
  const validateIdentityCode = async (code: string, dsns: string) => {
    console.log({ action: "validateIdentityCode()", code, dsns });
    try {
      // return ;
      const resp = await fetch(
        `/oauth/callback/identity_code?code=${code}&dsns=${dsns}`
      );

      const data = await resp.json();
      console.log({ action: "validateIdentityCode", data });
      setUserInfo(data);

      getUserInfo();  // 重新從 server 取得使用者身份。
    } catch (ex) {
      console.log({ ex });
   //   navigate("auth/not-signin");
    }
  };

  /** 登出 */
  const signout = async () => {
    console.log("signout in userContext ");
    await fetch("/api/auth/logout");
    setUserInfo(null);
 //   navigate("auth/not-signin");
  };

  useEffect(() => {
    getUserInfo();

   /* if (code && dsns) {
      validateIdentityCode(code, dsns);
    } else {
      getUserInfo();
    }*/
  }, []);

  return (
    <UserInfoContext.Provider
      value={{
        userInfo,        
        logout: signout,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
}

export default UserContext;

export function useUserContext() {
  return useContext(UserInfoContext);
}

export declare interface UserContextProps {
  children?: React.ReactNode;
}

export declare interface ParentInfo {
  seatNo: number;
  classID: number;
  classNo: string;
  className: string;
  gradeYear: number;
  studentID: number;
  studentName: string;
  relationship: string;
  studentNumber: string;
  studentSourceIndex: string;
}

export declare interface TeacherInfo {
  teacherID: number;
  teacherNo: string;
  teacherName: string;
  teacherSourceIndex: string;
}

export declare interface StudentInfo {
  seatNo: number;
  classID: number;
  classNo: string;
  className: string;
  gradeYear: number;
  studentID: number;
  studentName: string;
  studentNumber: string;
  studentSourceIndex: string;
}

export declare interface UserInfo {
  accountCenter: string;
  fullName: string;
  account: string;
  mail: string;
  uuid: string;
  photo: string;
  isAdmin: boolean;
  teacherDetail: any | null;
  studentDetail: any | null;
  parentDetail: any | null;
  unusual: any | null;
}

export declare interface UserInfoCtx {
  userInfo: UserInfo | null;
  logout: () => Promise<void> | null;
}
