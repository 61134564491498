// 研習資訊頁面
import { News } from "./News";
export const Info = () => {
  return (
    <div>
      <div className="mb-20">
        <div className="flex items-center staticBanner bg-[#cbbef0] relative">
          <div className="staticBannerContent">
            <img
              src="/images/infoBanner.svg"
              alt="banner"
              className="staticBannerImg"
            />
            <div className="text-base sm:text-lg md:text-3xl absolute w-[40%] max-w-[400px] translate-x-[59%] max-h-full overflow-auto">
              <h2 className="font-black text-primaryDark">教師數位研習資源</h2>
              <p className="text-secondaryDark mt-[0.5em] text-[0.65em] leading-normal">
                參加高雄市數位研習活動，掌握最新教學科技，提升教學智慧，讓學生學習效果更卓越！這裡專門提供教師的研習資訊，助您持續進步。
              </p>
            </div>
          </div>
        </div>
        <div className="staticSection">
          <div className="sectionContent">
            <div className="border-2 border-headline relative text-sm sm:text-2xl 2xl:text-4xl">
              <div className="flex justify-center items-center font-bold absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-2 text-Headline text-nowrap">
                <span className="text-[0.55em]">〇〇〇</span>
                <h3 className="px-1">下載專區</h3>
                <span className="text-[0.55em]">〇〇〇</span>
              </div>
              <div className="px-6 py-8 sm:px-12 sm:py-16 flex justify-center md:gap-10 text-center text-[0.9em]">
                <a
                  href="https://drive.google.com/drive/folders/18M-yPREvd4JZn8-fUv_3g4AIdrIDaRPH?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=""
                >
                  <button className="roundedAnimationBtn bg-primary text-white  flex-1">
                    <div className="p-2 sm:p-4 bg-white w-1/4 h-full">
                      <img
                        src="/images/zoom-view.svg"
                        alt=""
                        className="w-auto h-full object-contain object-center"
                      />
                    </div>
                    <div className="grow ">研習課程清單下載</div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <News />
      </div>
    </div>
  );
};
