/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import pre from "../assets/img/pre.svg";
import next from "../assets/img/next.svg";
import { useEffect, useState } from "react";
import { annoHelper } from "../dal/annoHelper";
import { useNavigate } from 'react-router-dom';
import config from "../SysConfig.json";
import dayjs from "dayjs";

export const News = () => {
  const [allNews, setAllNews] = useState<any[]>([]);
  const [activePage, setActivePage] = useState(0);
  const [currPageGroup, setCurrPageGroup] = useState(0);
  const [annoCount, setAnnoCount] = useState(0);

  const navigate = useNavigate();

  const handleDecPageGroup = () => {
    if (currPageGroup !== 0) {
      setCurrPageGroup(currPageGroup - 1)
      setActivePage((currPageGroup - 1) * config.Pagination.page_group_size - 1)
    }

  }

  const handleIncPageGroup = () => {
    if (currPageGroup !== Math.floor(annoCount / config.Pagination.page_group_size)) {
      setCurrPageGroup(currPageGroup + 1)
      setActivePage((currPageGroup + 1) * config.Pagination.page_group_size - 1)
    }
  }

  const handleActivatePage = (pageNum: number) => {
    if ((pageNum - 1) < annoCount / config.Pagination.page_size)
      setActivePage(pageNum - 1)
  }

  const handleItemClick = (item: any) => {
    navigate('/newsview', { state: { item } });
  }

  const loadData = async () => {
    const annos = await annoHelper.getOpenConditionalAnno('', config.Pagination.page_size, activePage);
    setAllNews(annos);
    const annosCount = await annoHelper.getOpenAnnoCount();
    setAnnoCount(annosCount.count);
  }
  useEffect(() => {
    loadData();
  }, [activePage]);
  return (
    <>
      <div className='content text-Headline px-4 mx-auto'>
        <div className='text-base lg:text-4xl w-fit border-b-4 border-primary font-medium mt-6 mb-4 lg:mt-16 lg:mb-12 p-1 lg:p-3 mx-auto'>最新消息</div>
        <div className='border-[3px] border-primary custom-shadow p-8 pt-4 lg:px-10 lg:py-8'>
          <div className='min-h-[320px]'>
            {
              allNews.map((anno) => {
                return (
                  <div className='flex justify-between items-center text-sm lg:text-2xl gap-4 my-4'>
                    <div className='flex items-center gap-5 lg:gap-10'>
                      {
                        (anno.is_top) ?
                          <div className='bt-yellow flex-shrink-0 w-[96px] lg:w-[152px] py-2'>{anno.unit_name}</div> :
                          <div className='bt-purple flex-shrink-0 w-[96px] lg:w-[152px] py-2'>{anno.unit_name}</div>
                      }
                      <a href='javascript:void(0);' onClick={() => handleItemClick(anno)} className='hover:underline clamp2'>{anno.title}</a>
                    </div>
                    <div className='hidden lg:block'>{dayjs(anno.created_at).format('YYYY-MM-DD')}</div>
                  </div>
                )
              })
            }

          </div>
          {/* <div className='flex justify-end text-sm lg:text-base cursor-pointer hover:opacity-80 mb-4'>查看更多
            <img src={more} alt='' className='ms-2' />
          </div> */}

          <div className="text-center py-4">
            <nav className="inline-flex items-center gap-3 lg:gap-4" aria-label="Pagination">
              <a className="hover:opacity-70 focus-visible:outline-none cursor-pointer" onClick={() => handleDecPageGroup()}>
                <span><img src={pre} alt='' /></span>
              </a>
              {
                Array.from({ length: config.Pagination.page_group_size }).map
                  ((_, i) =>
                    (currPageGroup * config.Pagination.page_group_size + i + 1 === activePage + 1) ?
                      <a className="w-6 h-6 leading-6 hover:opacity-70 focus-visible:outline-none rounded-full text-white bg-primary cursor-pointer" onClick={() => handleActivatePage(currPageGroup * config.Pagination.page_group_size + i + 1)}>{activePage + 1}</a> :
                      <a className="w-6 h-6 leading-6 hover:opacity-70 focus-visible:outline-none text-primary cursor-pointer" onClick={() => handleActivatePage(currPageGroup * config.Pagination.page_group_size + i + 1)}>{currPageGroup * config.Pagination.page_group_size + i + 1}</a>
                  )
              }
              <a className="hover:opacity-70 focus-visible:outline-none cursor-pointer" onClick={() => handleIncPageGroup()}>
                <span><img src={next} alt='' /></span>
              </a>
            </nav>
          </div>
        </div>
      </div>

    </>
  )

}