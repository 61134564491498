import { Outlet } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import UserContext, { useUserContext } from "../../components/auth/userContext";


export const MainPage = () => {  
  return (
    <>
    <UserContext>
      <Header /> 
      <div className="w-full border-b-2 border-[#51505033]"></div>
      <div className="min-h-[40vh]">
        <Outlet />
      </div>
      <div className="p-7 lg:p-16"></div>
      <Footer />
    </UserContext>
    </>
  )
}