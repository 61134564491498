import res01 from "../assets/img/res01.png"
import res02 from "../assets/img/res02.png"
import res03 from "../assets/img/res03.png"
import res04 from "../assets/img/res04.png"
import res05 from "../assets/img/res05.png"
import res06 from "../assets/img/res06.png"
import res07 from "../assets/img/res07.png"
import res08 from "../assets/img/res08.png"
import res09 from "../assets/img/res09.png"
import res10 from "../assets/img/res10.png"
import banner02 from "../assets/img/banner02.png"

import icon0 from "../assets/img/icon0.svg"
import icon1 from "../assets/img/icon1.svg"
import icon2 from "../assets/img/icon2.svg"
import { useEffect, useState } from "react"
import { settingsHelper } from "../dal/settingsHelper"
import { servicesHelper } from "../dal/servicesHelper"
import { CarouselPage } from "./CarouselPage"
import { handleLinkWithBlank } from "../modules/utils"

const digit = [
  { name: 'LoiLoNote School', img: res01, href: 'https://loilonote.app/login', note: '運用各種數位媒體素材，即時收集並分享每位學生的回答，創造出以學生為主體的雙向互動式學習模式。' },
  { name: 'Adobe Creative Cloud', img: res04, href: 'https://adobe.kh.edu.tw/#/', note: '提供各種應用程式、可用於您的所有創意專案－攝影、繪圖、影片編輯、UX 設計等。' },
  { name: '威力導演', img: res05, href: 'repair', note: '易於使用且功能強大的影音剪輯軟體，讓您輕鬆打造令人驚艷的影片作品，享受專業級的創作體驗。' },
  { name: '作文易學堂', img: res06, href: 'https://www.ezwriting.com.tw/login.aspx', note: '練功房語文素養八大館，超過6,100道題庫自我挑戰，60個主題動畫寫作教室，1,700篇範文，以及每週7項主題任務，完成任務可獲得金幣，兌換實質禮品。' },
  { name: '中文能力雲端診斷系統', img: res07, href: 'https://cwt.kh.edu.tw/', note: '學生自學功能包含語文素養線上題庫即測即評與回饋、錯題解析、國語文各面向能力分析、與大數據比較的落點分析、學習歷程與電子稿紙。' },
];

const resource = [
  { name: 'LoiLoNote School', img: res01, href: 'https://loilonote.app/login', note: '運用各種數位媒體素材，即時收集並分享每位學生的回答，創造出以學生為主體的雙向互動式學習模式。' },
  { name: 'HyRead國語日報知識庫', img: res08, href: 'https://mdnkids.ebook.hyread.com.tw/', note: '即時報紙全文內容，提供師生在校即可透過電腦大螢幕、手機、平板等線上閱讀，是老師備課、教學、班級共讀及學生自主學習的最佳工具。' },
  { name: 'Myviewboard & ClassSwift', img: res09, href: 'repair', note: '數位白板不該只是簡報工具，而是協助您學習、備課與授課的良伴，是您教室內最亮眼的存在，引導學習與互動的最棒工具' },
  { name: 'HyRead行動學習墊子雜誌庫', img: res10, href: 'https://khedu.ebook.hyread.com.tw/', note: '授權包含ABC互動英語、Live互動英語、科學月刊、How it works知識大圖解、國家地理雜誌、少年牛頓、幼獅文藝、全球中央。' },
];

export const Resource = () => {
  const [serviceTags, setServiceTags] = useState<string[]>([]);  // 服務類別標籤
  const [appList, setAppList] = useState([]);  //可用服務列表


  const loadData = async () => {
    try {
      const [appList, serviceTag] = await Promise.all([
        servicesHelper.getOpenAppList(),
        settingsHelper.getOpenGlobalSettingByKey('service_tag')
      ]);

      if (appList) {
        appList.sort((a: any, b: any) => {
          if (a.service_tag === b.service_tag) {
            return a.display_order - b.display_order;
          }
          return a.service_tag.localeCompare(b.service_tag, 'zh-Hant-TW');
        });
      }

      const serviceTags = serviceTag.value.split(',');
      setServiceTags(serviceTags || []);
      setAppList(appList);

    } catch (error) {
      console.error('Error loading data:', error);
    }   
  }
  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <div>
        <CarouselPage />
      </div>
      <div className="content px-4 mx-auto pt-2">
      {serviceTags.map((tag) => {
        const filteredApps = appList.filter((app: any) => app.service_tag === tag);
        if (filteredApps.length > 0) {
          return (
            <div key={tag}>
              <div className="text-xl md:text-9 font-medium text-Headline mt-12 md:mt-16 mb-6 md:mb-8">
                {tag}
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 text-Headline">
                {filteredApps.map((app: any) => (
                  <div key={app.name} className="home-resbox cursor-pointer" onClick={()=>(app.entry_url) ? handleLinkWithBlank(app.entry_url):null}>
                    <div className="home-img-container">
                      <img src={app.logo_url} alt={app.name} className="home-app-image" />
                    </div>
                    {(app.entry_url) ? 
                      <a
                        href={app.entry_url}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="home-app-link text-base font-medium m-3"
                      >
                        {app.name}
                      </a> : <div className="home-app-link text-base font-medium m-3">{app.name}</div>
                      }
                    {/*<div className="home-text-sm mx-3 my-4">{app.service_desc}</div>*/}
                  </div>
                ))}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      </div>

      <div className='p-7 lg:p-16'></div>
    </>
  )
}